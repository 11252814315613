var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import { WebsocketContext } from "./WebsocketProvider";
function MovePlayerButton(relative, children) {
    var component = function (props) {
        var players = props.players, player = props.player;
        var send = React.useContext(WebsocketContext).send;
        var movePlayer = function () {
            var index = players.findIndex(function (p) { return p === player; });
            var newIndex = (index + relative) % players.length;
            var withoutPlayer = players.filter(function (p) { return p !== player; });
            var newPlayers = __spreadArray(__spreadArray(__spreadArray([], withoutPlayer.slice(0, newIndex), true), [
                player
            ], false), withoutPlayer.slice(newIndex, withoutPlayer.length), true);
            send({ Action: { ReorderPlayers: newPlayers.map(function (p) { return p.id; }) } });
        };
        return React.createElement("button", { onClick: movePlayer }, children);
    };
    component.displayName = "MovePlayerButton";
    return component;
}
export var MovePlayerLeft = MovePlayerButton(-1, "<");
export var MovePlayerRight = MovePlayerButton(1, ">");

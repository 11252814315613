import * as React from "react";
import ReactModal from "react-modal";
import { AppStateContext } from "./AppStateProvider";
var contentStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
};
var changeLogVersion = 23;
var ChangeLog = function () {
    var _a = React.useState(false), modalOpen = _a[0], setModalOpen = _a[1];
    var _b = React.useContext(AppStateContext), state = _b.state, updateState = _b.updateState;
    React.useEffect(function () {
        if (state.changeLogLastViewed !== changeLogVersion) {
            setModalOpen(true);
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("a", { onClick: function (evt) {
                evt.preventDefault();
                setModalOpen(true);
            }, href: window.location.href }, "Change Log"),
        React.createElement(ReactModal, { isOpen: modalOpen, onRequestClose: function () {
                setModalOpen(false);
                updateState({ changeLogLastViewed: changeLogVersion });
            }, shouldCloseOnOverlayClick: true, shouldCloseOnEsc: true, style: { content: contentStyle } },
            React.createElement("h2", null, "For new players"),
            React.createElement("p", null,
                "If you haven't learned to play the game yet, consider reading the",
                " ",
                React.createElement("a", { href: "rules.html", target: "_blank" }, "rules"),
                "."),
            React.createElement("p", null, "There are a wide variety of game settings which may suit the way you normally play, e.g. changing how many decks, how scoring works, etc. These can be changed before every round."),
            React.createElement("p", null, "There are also a bunch of UI customizations that you may want to turn on (or leave off) -- click the gear icon at the top of the screen once you're in the game."),
            React.createElement("h2", null, "Change Log"),
            React.createElement("p", null, "7/10/2023:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Added a confirmation check from another player when resetting the game")),
            React.createElement("p", null, "1/20/2024:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Added the ability to protect both longer tuples and tractors at the same time.")),
            React.createElement("p", null, "2/24/2023:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Added the ability to list a room publicly for others to join (thanks jimmyfang94!)")),
            React.createElement("p", null, "1/18/2023:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Fixed performance issue when playing tricks with many cards"),
                React.createElement("li", null, "Added suggested play button")),
            React.createElement("p", null, "1/11/2023:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Fixed rendering of card icons")),
            React.createElement("p", null, "1/10/2023:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Changed default UI setting to \u201Cshow cards in player order\u201D."),
                React.createElement("li", null, "Added icons (can be turned off) for point cards and trump cards.")),
            React.createElement("p", null, "12/28/2023:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Fix bug where over-trumping a trumped throw would sometimes not work.")),
            React.createElement("p", null, "9/25/2022:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Add the ability to disable joker bids"),
                React.createElement("li", null, "Add the ability to set autodraw speed")),
            React.createElement("p", null, "4/17/2022:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Add the ability to hide the chat box and move the points progress bar in the settings pane.")),
            React.createElement("p", null, "3/07/2022:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Add the ability to choose the final level (NT or A) in difficulty settings.")),
            React.createElement("p", null, "2/05/2022:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Added long-missing support for no-trump rank after A."),
                React.createElement("li", null, "Added setting to show player name in title bar."),
                React.createElement("li", null, "Added ability to set meta-rank (behind checkbox).")),
            React.createElement("p", null, "7/04/2021:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Added option to customize the minimum tractor size under \u201Cmore game settings\u201D")),
            React.createElement("p", null, "6/18/2021:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Added option for higher suit non-joker bids to outbid non-joker bids.")),
            React.createElement("p", null, "3/21/2021:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Added option to view (most of) the UI in dark mode."),
                React.createElement("li", null, "Added button to randomize the player order."),
                React.createElement("li", null, "Added button to check if everyone is ready.")),
            React.createElement("p", null, "3/15/2021:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Added option in Finding Friends to select friends using trumps.")),
            React.createElement("p", null, "2/15/2021:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Support protecting tractors from four-of-a-kind.")),
            React.createElement("p", null, "2/4/2021:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Support configuring custom deck properties, like short decks or removing jokers.")),
            React.createElement("p", null, "2/2/2021:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Fix bug where unselecting cards would temporarily remove them from the game.")),
            React.createElement("p", null, "1/31/2021:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Players can now choose kitty sizes which require cards to be removed from the game.")),
            React.createElement("p", null, "1/27/2021:"),
            React.createElement("ul", null,
                React.createElement("li", null, "When ending the game early, let players see what cards were remaining.")),
            React.createElement("p", null, "1/22/2021:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Add the ability to end the game early when there are insufficient points remaining to matter.")),
            React.createElement("p", null, "1/21/2021:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Add a scoring progress bar with point thresholds."),
                React.createElement("li", null, "Add a setting to prevent friends from joining twice (in difficulty settings).")),
            React.createElement("p", null, "1/18/2021:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Amend the \u201CPointCardNotAllowed\u201D friend selection policy. King is now a valid friend when the landlord's rank is Ace.")),
            React.createElement("p", null, "1/8/2021:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Add settings for reinforcing a bid after it has been overturned, and for overbidding yourself."),
                React.createElement("li", null, "Add a setting to show debug information, to help with more detailed bug reports.")),
            React.createElement("p", null, "12/11/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Support a friend selection policy that disallows point cards.")),
            React.createElement("p", null, "12/07/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Add a setting which hides the indication of which player that can defeat a throw."),
                React.createElement("li", null, "Add a card-protection setting which disables format-based play requirements.")),
            React.createElement("p", null, "11/22/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null,
                    "More rigorously define trick-format decomposition, especially when more than four decks are involved. See the issues referenced in",
                    " ",
                    React.createElement("a", { href: "https://github.com/rbtying/shengji/pull/258/files", target: "_blank", rel: "noreferrer" }, "PR #258"),
                    " ",
                    "for details.")),
            React.createElement("p", null, "11/13/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Fix bug in longest-component throw-evaluation policy where the winner for tricks of single cards was always the first player.")),
            React.createElement("p", null, "11/11/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Support a throw evaluation policy based on the longest component.")),
            React.createElement("p", null, "11/01/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Support more granular step sizes in scoring settings on a per-number-of-decks basis.")),
            React.createElement("p", null, "9/27/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Support limiting joker/no-trump bids in games with more than two decks.")),
            React.createElement("p", null, "9/18/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Fix performance issues in long games.")),
            React.createElement("p", null, "8/30/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Support end of game kitty reveal.")),
            React.createElement("p", null, "8/09/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Support configuring different score thresholds for each game.")),
            React.createElement("p", null, "8/07/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Allow card colors to be customized"),
                React.createElement("li", null, "Add option to play sound during draw. Sound sourced from dersuperanton at freesound.org")),
            React.createElement("p", null, "8/02/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Support beeps in exchange phase")),
            React.createElement("p", null, "7/26/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Fix a bug where throws in trump of the trump-rank-card would be incorrectly blocked"),
                React.createElement("li", null, "Implement helper which lets you know what plays you can make and tells you about format-decompositions"),
                React.createElement("li", null, "Allow player to specify preferred grouping in case of ambiguity, e.g. 22333 as either [22][333] or [2233][3]"),
                React.createElement("li", null, "Add UI hint which shows you cards in the same suit"),
                React.createElement("li", null, "Add UI setting which allows you to separate cards by suit")),
            React.createElement("p", null, "7/23/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Move a bunch of settings into modals to make interface cleaner")),
            React.createElement("p", null, "7/19/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Bid by clicking on a predefined set of valid bids")),
            React.createElement("p", null, "7/18/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Allow zero-sized kitty in the UI")),
            React.createElement("p", null, "7/15/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Add game option for limiting who can start a game")),
            React.createElement("p", null, "7/09/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Add a game option for (disallowing) shadowing of other players")),
            React.createElement("p", null, "7/02/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "(#21) Add a screen and confetti when you successfully defend A!")),
            React.createElement("p", null, "7/02/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "(#171) Add game option disable taking back bids"),
                React.createElement("li", null, "(#68) Add game option disable taking back plays"),
                React.createElement("li", null, "(#17) Add game option for \u201Cstealing\u201D the bottom cards")),
            React.createElement("p", null, "7/01/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Add the option to use SVG cards rather than text cards.")),
            React.createElement("p", null, "6/28/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "(#163) add game option to reward a bonus level for landlord team to win with a smaller size team")),
            React.createElement("p", null, "6/26/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "(#160) add game option to allow outbid only with more cards")),
            React.createElement("p", null, "6/25/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "(#158) add user option to display bid cards in separate row in Draw stage")),
            React.createElement("p", null, "6/24/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "(#156) add FirstLandlordSelectionPolicy to set the first bidder as landlord when no landlord is selected")),
            React.createElement("p", null, "6/21/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "(#145) Save, load, reset game settings"),
                React.createElement("li", null, "(#154) Landlord emoji option")),
            React.createElement("p", null, "6/20/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Add the ability to wrap-around after defending on A."),
                React.createElement("li", null, "Show throw breakdowns in the UI to make throws more obvious.")),
            React.createElement("p", null, "6/17/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Fix bug where points display was highlighted blue.")),
            React.createElement("p", null, "6/14/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Fix bug where previous-trick showed current trick."),
                React.createElement("li", null, "(#134) Fix bug where defend-points allowed defending team to skip defending points.")),
            React.createElement("p", null, "6/13/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "(#133) Improve trick list to show landlord, better coloring of team and winning trick.")),
            React.createElement("p", null, "6/12/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "(#131) Add option to disallow using highest non-trump card to select friend.")),
            React.createElement("p", null, "6/7/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "(#127) Simplify friend selection description.")),
            React.createElement("p", null, "6/6/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "(#125) Highlight all members of the landlord's team in the trick view.")),
            React.createElement("p", null, "6/5/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Fix a bug (#35) so as to disallow picking trump cards as friend.")),
            React.createElement("p", null, "5/25/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Fix bug in longest-tuple-protected mode where tractors of longer tuples would erroneously get drawn out."),
                React.createElement("li", null, "Add support for throw evaluation based on the highest card in the throw.")),
            React.createElement("p", null, "5/24/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Add game result statistics tracking.")),
            React.createElement("p", null, "5/13/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Add an option to protect triples from being drawn out by pairs"),
                React.createElement("li", null, "Fill in the suit character in the trump UI")),
            React.createElement("p", null, "5/8/2020:"),
            React.createElement("ul", null,
                React.createElement("li", null, "When leader is set to winner-of-bid, players bid their own levels rather than a random selected one."),
                React.createElement("li", null, "Card labels are not visible above the settings pane."),
                React.createElement("li", null, "Cards can be revealed from the bottom when the deck is fully drawn to determine trump.")),
            React.createElement("hr", null),
            React.createElement("p", null, "Changes prior to 5/8/2020 not listed"))));
};
var Credits = function () { return (React.createElement("p", { style: { padding: "0 20px" } },
    "Made by Robert Ying (",
    React.createElement("a", { href: "mailto:me@robertying.com" }, "me@robertying.com"),
    "), Abra Shen, and other",
    " ",
    React.createElement("a", { href: "https://github.com/rbtying/shengji/graphs/contributors", target: "_blank", rel: "noreferrer" }, "friends"),
    ". Consider buying us boba via Venmo at @Robert-Ying or via",
    " ",
    React.createElement("a", { href: "https://donate.stripe.com/aEU8x982f3oj4Ja7ss", target: "_blank", rel: "noreferrer" }, "other payment methods"),
    ", or contributing on",
    " ",
    React.createElement("a", { href: "https://github.com/rbtying/shengji", target: "_blank", rel: "noreferrer" }, "GitHub"),
    "!",
    React.createElement("span", { style: { float: "right" } },
        React.createElement(ChangeLog, null)))); };
export default Credits;

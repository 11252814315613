import * as React from "react";
import Cards from "./Cards";
import { WebsocketContext } from "./WebsocketProvider";
import LabeledPlay from "./LabeledPlay";
import WasmContext from "./WasmContext";
var BidArea = function (props) {
    var _a, _b;
    var send = React.useContext(WebsocketContext).send;
    var findValidBids = React.useContext(WasmContext).findValidBids;
    var trump = props.trump == null ? { NoTrump: {} } : props.trump;
    var takeBackBid = function (evt) {
        evt.preventDefault();
        send({ Action: "TakeBackBid" });
    };
    var players = {};
    var playerId = -1;
    props.players.forEach(function (p) {
        players[p.id] = p;
        if (p.name === props.name) {
            playerId = p.id;
        }
    });
    if (playerId === null || playerId < 0) {
        // Spectator mode
        return (React.createElement("div", null,
            props.header,
            props.autobid !== null ? (React.createElement(LabeledPlay, { label: "".concat(players[props.autobid.id].name, " (from bottom)"), trump: trump, cards: [props.autobid.card] })) : null,
            props.bids.map(function (bid, idx) {
                var name = players[bid.id].name;
                return (React.createElement(LabeledPlay, { label: name, key: idx, trump: trump, cards: Array(bid.count).fill(bid.card) }));
            }),
            props.bids.length === 0 && props.autobid === null ? (React.createElement(LabeledPlay, { trump: trump, label: "No bids yet...", cards: ["🂠"] })) : null));
    }
    else {
        var validBids = findValidBids({
            id: playerId,
            bids: props.bids,
            hands: props.hands,
            players: props.players,
            landlord: props.landlord,
            epoch: props.epoch,
            bid_policy: props.bidPolicy,
            bid_reinforcement_policy: props.bidReinforcementPolicy,
            joker_bid_policy: props.jokerBidPolicy,
            num_decks: props.numDecks,
        });
        var levelId = props.landlord !== null && props.landlord !== undefined
            ? props.landlord
            : playerId;
        var trump_1 = props.trump !== null && props.trump !== undefined
            ? props.trump
            : {
                NoTrump: {
                    number: players[levelId].level !== "NT" ? players[levelId].level : null,
                },
            };
        validBids.sort(function (a, b) {
            if (a.card < b.card) {
                return -1;
            }
            else if (a.card > b.card) {
                return 1;
            }
            else if (a.count < b.count) {
                return -1;
            }
            else if (a.count > b.count) {
                return 1;
            }
            else {
                return 0;
            }
        });
        return (React.createElement("div", null,
            React.createElement("div", null,
                props.header,
                props.autobid !== null ? (React.createElement(LabeledPlay, { label: "".concat(players[props.autobid.id].name, " (from bottom)"), cards: [props.autobid.card], trump: trump_1 })) : null,
                props.bids.map(function (bid, idx) {
                    var name = players[bid.id].name;
                    return (React.createElement(LabeledPlay, { label: name, key: idx, trump: trump_1, cards: Array(bid.count).fill(bid.card) }));
                }),
                props.trump !== undefined &&
                    "NoTrump" in props.trump &&
                    ((_b = (_a = props.trump) === null || _a === void 0 ? void 0 : _a.NoTrump) === null || _b === void 0 ? void 0 : _b.number) === null ? (React.createElement(React.Fragment, null, "No bidding in no trump!")) : props.bids.length === 0 && props.autobid === null ? (React.createElement(LabeledPlay, { trump: trump_1, label: "No bids yet...", cards: ["🂠"] })) : null),
            props.prefixButtons,
            props.bidTakeBacksEnabled ? (React.createElement("button", { onClick: takeBackBid, disabled: props.bids.length === 0 ||
                    props.bids[props.bids.length - 1].id !== playerId ||
                    props.bids[props.bids.length - 1].epoch !== props.epoch, className: "big" }, "Take back bid")) : null,
            props.suffixButtons,
            validBids.length > 0 ? (React.createElement("p", null, "Click a bid option to bid")) : (React.createElement("p", null, "No available bids!")),
            validBids.map(function (bid, idx) {
                return (React.createElement(LabeledPlay, { trump: trump_1, cards: Array(bid.count).fill(bid.card), key: idx, label: "Bid option ".concat(idx + 1), onClick: function () {
                        send({ Action: { Bid: [bid.card, bid.count] } });
                    } }));
            }),
            React.createElement(Cards, { hands: props.hands, playerId: playerId, trump: trump_1 })));
    }
};
export default BidArea;

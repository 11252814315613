import * as React from "react";
import ChatInput from "./ChatInput";
import ChatMessage from "./ChatMessage";
import { WebsocketContext } from "./WebsocketProvider";
import { SettingsContext } from "./AppStateProvider";
var Chat = function (props) {
    var anchor = React.useRef(null);
    var send = React.useContext(WebsocketContext).send;
    var settings = React.useContext(SettingsContext);
    React.useEffect(function () {
        var _a;
        if (anchor.current !== null) {
            var rect = anchor.current.getBoundingClientRect();
            var html = document.documentElement;
            var isVisible = rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || html.clientHeight) &&
                rect.right <= (window.innerWidth || html.clientWidth);
            if (isVisible) {
                (_a = anchor.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: "nearest", inline: "start" });
            }
        }
    }, [props.messages]);
    var handleSubmit = function (message) { return send({ Message: message }); };
    return (React.createElement(React.Fragment, null, !settings.hideChatBox && (React.createElement("div", { className: "chat" },
        React.createElement("div", { className: "messages" },
            props.messages.map(function (m, idx) { return (React.createElement(ChatMessage, { message: m, key: idx })); }),
            React.createElement("div", { className: "chat-anchor", ref: anchor })),
        React.createElement(ChatInput, { onSubmit: handleSubmit })))));
};
export default Chat;

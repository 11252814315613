import { combineState } from "../State";
import { booleanLocalStorageState, JSONLocalStorageState, nullableNumberLocalStorageState, stringLocalStorageState, } from "../localStorageState";
var fourColor = booleanLocalStorageState("four_color");
var darkMode = booleanLocalStorageState("dark_mode");
var svgCards = booleanLocalStorageState("svg_cards");
var showCardLabels = booleanLocalStorageState("show_card_labels");
var showLastTrick = booleanLocalStorageState("show_last_trick");
var beepOnTurn = booleanLocalStorageState("beep_on_turn");
var reverseCardOrder = booleanLocalStorageState("reverse_card_order");
var unsetAutoPlayWhenWinnerChanges = booleanLocalStorageState("unset_autoplay_on_winner_change");
var showTrickInPlayerOrder = booleanLocalStorageState("show_trick_in_player_order", true);
var separateCardsBySuit = booleanLocalStorageState("separate_cards_by_suit");
var disableSuitHighlights = booleanLocalStorageState("disable_suit_highlights");
var suitColorOverrides = JSONLocalStorageState("suit_color_overrides", {});
var playDrawCardSound = booleanLocalStorageState("play_draw_card_sound");
var showDebugInfo = booleanLocalStorageState("show_debug_info");
var showPlayerName = booleanLocalStorageState("show_player_name_in_title");
var hideChatBox = booleanLocalStorageState("hide_chat_box");
var showPointsAboveGame = booleanLocalStorageState("points_above_game");
export var DEFAULT_POINT_CARD_ICON = "💰";
var pointCardIcon = stringLocalStorageState("point_card_icon", DEFAULT_POINT_CARD_ICON);
export var DEFAULT_TRUMP_CARD_ICON = "👑";
var trumpCardIcon = stringLocalStorageState("trump_card_icon", DEFAULT_TRUMP_CARD_ICON);
var autodrawSpeedMs = nullableNumberLocalStorageState("autodrawSpeedMs", null);
var settings = combineState({
    fourColor: fourColor,
    darkMode: darkMode,
    showCardLabels: showCardLabels,
    showLastTrick: showLastTrick,
    beepOnTurn: beepOnTurn,
    reverseCardOrder: reverseCardOrder,
    unsetAutoPlayWhenWinnerChanges: unsetAutoPlayWhenWinnerChanges,
    showTrickInPlayerOrder: showTrickInPlayerOrder,
    svgCards: svgCards,
    separateCardsBySuit: separateCardsBySuit,
    disableSuitHighlights: disableSuitHighlights,
    suitColorOverrides: suitColorOverrides,
    playDrawCardSound: playDrawCardSound,
    showDebugInfo: showDebugInfo,
    showPlayerName: showPlayerName,
    hideChatBox: hideChatBox,
    showPointsAboveGame: showPointsAboveGame,
    pointCardIcon: pointCardIcon,
    trumpCardIcon: trumpCardIcon,
    autodrawSpeedMs: autodrawSpeedMs,
});
export default settings;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import ReactTooltip from "react-tooltip";
import ReactModal from "react-modal";
import { EmojiStyle } from "emoji-picker-react";
import ReadyCheck from "./ReadyCheck";
import LandlordSelector from "./LandlordSelector";
import NumDecksSelector from "./NumDecksSelector";
import KittySizeSelector from "./KittySizeSelector";
import RankSelector from "./RankSelector";
import Kicker from "./Kicker";
import ArrayUtils from "./util/array";
import { RandomizePlayersButton } from "./RandomizePlayersButton";
import { WebsocketContext } from "./WebsocketProvider";
import Header from "./Header";
import Players from "./Players";
import { GameScoringSettings } from "./ScoringSettings";
var Picker = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import("emoji-picker-react")];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); });
var contentStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "80%",
    transform: "translate(-50%, -50%)",
};
var DifficultySettings = function (props) {
    var _a = React.useState(false), modalOpen = _a[0], setModalOpen = _a[1];
    var s = (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement("label", null,
                "Friend selection restriction:",
                " ",
                React.createElement("select", { value: props.state.propagated.friend_selection_policy, onChange: props.setFriendSelectionPolicy },
                    React.createElement("option", { value: "Unrestricted" }, "Non-trump cards"),
                    React.createElement("option", { value: "TrumpsIncluded" }, "All cards, including trumps"),
                    React.createElement("option", { value: "HighestCardNotAllowed" }, "Non-trump cards, except the highest"),
                    React.createElement("option", { value: "PointCardNotAllowed" }, "Non-trump, non-point cards (except K when playing A)")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Multiple joining policy:",
                " ",
                React.createElement("select", { value: props.state.propagated.multiple_join_policy, onChange: props.setMultipleJoinPolicy },
                    React.createElement("option", { value: "Unrestricted" }, "Players can join the defending team multiple times."),
                    React.createElement("option", { value: "NoDoubleJoin" }, "Each player can only join the defending team once.")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Rank advancement policy:",
                " ",
                React.createElement("select", { value: props.state.propagated.advancement_policy, onChange: props.setAdvancementPolicy },
                    React.createElement("option", { value: "Unrestricted" }, "A must be defended"),
                    React.createElement("option", { value: "FullyUnrestricted" }, "Unrestricted"),
                    React.createElement("option", { value: "DefendPoints" }, "Points (5, 10, K) and A must be defended")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Max rank:",
                " ",
                React.createElement("select", { value: props.state.propagated.max_rank, onChange: props.setMaxRank },
                    React.createElement("option", { value: "NT" }, "No trump"),
                    React.createElement("option", { value: "A" }, "A")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Point visibility:",
                " ",
                React.createElement("select", { value: props.state.propagated.hide_landlord_points ? "hide" : "show", onChange: props.setHideLandlordsPoints },
                    React.createElement("option", { value: "show" }, "Show all players' points"),
                    React.createElement("option", { value: "hide" }, "Hide defending team's points")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Played card visibility (in chat):",
                " ",
                React.createElement("select", { value: props.state.propagated.hide_played_cards ? "hide" : "show", onChange: props.setHidePlayedCards },
                    React.createElement("option", { value: "show" }, "Show played cards in chat"),
                    React.createElement("option", { value: "hide" }, "Hide played cards in chat")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Penalty for points left in the bottom:",
                " ",
                React.createElement("select", { value: props.state.propagated.kitty_penalty, onChange: props.setKittyPenalty },
                    React.createElement("option", { value: "Times" }, "Twice the size of the last trick"),
                    React.createElement("option", { value: "Power" }, "Two to the power of the size of the last trick")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Penalty for incorrect throws:",
                " ",
                React.createElement("select", { value: props.state.propagated.throw_penalty, onChange: props.setThrowPenalty },
                    React.createElement("option", { value: "None" }, "No penalty"),
                    React.createElement("option", { value: "TenPointsPerAttempt" }, "Ten points per bad throw")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Play takeback:",
                " ",
                React.createElement("select", { value: props.state.propagated.play_takeback_policy, onChange: props.setPlayTakebackPolicy },
                    React.createElement("option", { value: "AllowPlayTakeback" }, "Allow taking back plays"),
                    React.createElement("option", { value: "NoPlayTakeback" }, "Disallow taking back plays")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Bid takeback:",
                " ",
                React.createElement("select", { value: props.state.propagated.bid_takeback_policy, onChange: props.setBidTakebackPolicy },
                    React.createElement("option", { value: "AllowBidTakeback" }, "Allow bid takeback"),
                    React.createElement("option", { value: "NoBidTakeback" }, "No bid takeback"))))));
    return (React.createElement("div", null,
        React.createElement("label", null,
            "Difficulty settings:",
            " ",
            React.createElement("button", { className: "normal", onClick: function (evt) {
                    evt.preventDefault();
                    setModalOpen(true);
                } }, "Open"),
            React.createElement(ReactModal, { isOpen: modalOpen, onRequestClose: function () { return setModalOpen(false); }, shouldCloseOnOverlayClick: true, shouldCloseOnEsc: true, style: { content: contentStyle } }, s))));
};
var DeckSettings = function (props) {
    var _a = React.useState(false), modalOpen = _a[0], setModalOpen = _a[1];
    var isNotDefault = function (d) {
        return !(d.min === "2" && !d.exclude_big_joker && !d.exclude_small_joker);
    };
    var onChange = function (decks) {
        // exclude the decks that are the same as default
        var filtered = decks.filter(function (d) { return isNotDefault(d); });
        props.setSpecialDecks(filtered);
    };
    var setDeckAtIndex = function (deck, index) {
        var newDecks = __spreadArray([], props.decks, true);
        newDecks[index] = deck;
        onChange(newDecks);
    };
    var numbers = [
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "J",
        "Q",
        "K",
        "A",
    ];
    var s = (React.createElement(React.Fragment, null, props.decks.map(function (d, i) { return (React.createElement("div", { key: i, style: {
            display: "inline-block",
            border: "1px solid #000",
            padding: "5px",
            margin: "5px",
        } },
        "Deck ",
        i + 1,
        isNotDefault(d) ? " (modified)" : " (standard)",
        React.createElement("form", null,
            React.createElement("label", { style: { display: "block" } },
                "Include HJ (\u5927\u738B)",
                " ",
                React.createElement("input", { type: "checkbox", checked: !d.exclude_big_joker, onChange: function (evt) {
                        return setDeckAtIndex(__assign(__assign({}, d), { exclude_big_joker: !evt.target.checked }), i);
                    } })),
            React.createElement("label", { style: { display: "block" } },
                "Include LJ (\u5C0F\u738B)",
                " ",
                React.createElement("input", { type: "checkbox", checked: !d.exclude_small_joker, onChange: function (evt) {
                        return setDeckAtIndex(__assign(__assign({}, d), { exclude_small_joker: !evt.target.checked }), i);
                    } })),
            React.createElement("label", null,
                "Minimum card:",
                " ",
                React.createElement("select", { value: d.min, onChange: function (evt) {
                        return setDeckAtIndex(__assign(__assign({}, d), { min: evt.target.value }), i);
                    } }, numbers.map(function (n) { return (React.createElement("option", { key: n, value: n }, n)); })))))); })));
    return (React.createElement("div", null,
        React.createElement("label", null,
            "More deck customization:",
            " ",
            React.createElement("button", { className: "normal", onClick: function (evt) {
                    evt.preventDefault();
                    setModalOpen(true);
                } }, "Open"),
            React.createElement(ReactModal, { isOpen: modalOpen, onRequestClose: function () { return setModalOpen(false); }, shouldCloseOnOverlayClick: true, shouldCloseOnEsc: true, style: { content: contentStyle } }, s))));
};
var TractorRequirementsE = function (props) {
    return (React.createElement("div", null,
        React.createElement("label", null, "Tractor requirements: "),
        React.createElement("input", { type: "number", style: { width: "3em" }, onChange: function (v) {
                return props.onChange(__assign(__assign({}, props.tractorRequirements), { min_count: v.target.valueAsNumber }));
            }, value: props.tractorRequirements.min_count, min: "2", max: props.numDecks }),
        React.createElement("label", null, " cards wide by "),
        React.createElement("input", { type: "number", style: { width: "3em" }, onChange: function (v) {
                return props.onChange(__assign(__assign({}, props.tractorRequirements), { min_length: v.target.valueAsNumber }));
            }, value: props.tractorRequirements.min_length, min: "2", max: "12" }),
        React.createElement("label", null, " tuples long")));
};
var ScoringSettings = function (props) {
    var _a = React.useState(false), modalOpen = _a[0], setModalOpen = _a[1];
    return (React.createElement("div", null,
        React.createElement("label", null,
            "Scoring settings:",
            " ",
            React.createElement("button", { className: "normal", onClick: function (evt) {
                    evt.preventDefault();
                    setModalOpen(true);
                } }, "Open"),
            React.createElement(ReactModal, { isOpen: modalOpen, onRequestClose: function () { return setModalOpen(false); }, shouldCloseOnOverlayClick: true, shouldCloseOnEsc: true, style: { content: contentStyle } },
                React.createElement(GameScoringSettings, { params: props.state.propagated.game_scoring_parameters, decks: props.decks })))));
};
var UncommonSettings = function (props) {
    var _a = React.useState(false), modalOpen = _a[0], setModalOpen = _a[1];
    var s = (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement("label", null,
                "Game shadowing policy:",
                " ",
                React.createElement("select", { value: props.state.propagated.game_shadowing_policy, onChange: props.setGameShadowingPolicy },
                    React.createElement("option", { value: "AllowMultipleSessions" }, "Allow players to be shadowed by joining with the same name"),
                    React.createElement("option", { value: "SingleSessionOnly" }, "Do not allow players to be shadowed")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Game start policy:",
                " ",
                React.createElement("select", { value: props.state.propagated.game_start_policy, onChange: props.setGameStartPolicy },
                    React.createElement("option", { value: "AllowAnyPlayer" }, "Allow any player to start a game"),
                    React.createElement("option", { value: "AllowLandlordOnly" }, "Allow only landlord to start a game")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Landlord selection from bid:",
                " ",
                React.createElement("select", { value: props.state.propagated.first_landlord_selection_policy, onChange: props.setFirstLandlordSelectionPolicy },
                    React.createElement("option", { value: "ByWinningBid" }, "Winning bid decides both landlord and trump"),
                    React.createElement("option", { value: "ByFirstBid" }, "First bid decides landlord, winning bid decides trump")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Trump policy for cards revealed from the bottom:",
                " ",
                React.createElement("select", { value: props.state.propagated.kitty_bid_policy, onChange: props.setKittyBidPolicy },
                    React.createElement("option", { value: "FirstCard" }, "First card revealed"),
                    React.createElement("option", { value: "FirstCardOfLevelOrHighest" }, "First card revealed of the appropriate rank")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Bid policy:",
                " ",
                React.createElement("select", { value: props.state.propagated.bid_policy, onChange: props.setBidPolicy },
                    React.createElement("option", { value: "JokerOrHigherSuit" }, "Joker or higher suit bids to outbid non-joker bids with the same number of cards"),
                    React.createElement("option", { value: "JokerOrGreaterLength" }, "Joker bids to outbid non-joker bids with the same number of cards"),
                    React.createElement("option", { value: "GreaterLength" }, "All bids must have more cards than the previous bids")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Bid reinforcement policy:",
                " ",
                React.createElement("select", { value: props.state.propagated.bid_reinforcement_policy, onChange: props.setBidReinforcementPolicy },
                    React.createElement("option", { value: "ReinforceWhileWinning" }, "The current winning bid can be reinforced"),
                    React.createElement("option", { value: "ReinforceWhileEquivalent" }, "A bid can be reinforced after it is overturned"),
                    React.createElement("option", { value: "OverturnOrReinforceWhileWinning" }, "The current winning bid can be overturned by the same bidder")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Joker bid policy:",
                " ",
                React.createElement("select", { value: props.state.propagated.joker_bid_policy, onChange: props.setJokerBidPolicy },
                    React.createElement("option", { value: "BothTwoOrMore" }, "At least two jokers (or number of decks) to bid no trump"),
                    React.createElement("option", { value: "BothNumDecks" }, "All the low or high jokers to bid no trump"),
                    React.createElement("option", { value: "LJNumDecksHJNumDecksLessOne" }, "All the low jokers or all but one high joker to bid no trump"),
                    React.createElement("option", { value: "Disabled" }, "No trump / joker bids disabled")))),
        React.createElement(TractorRequirementsE, { tractorRequirements: props.state.propagated.tractor_requirements, numDecks: props.numDecksEffective, onChange: function (req) { return props.setTractorRequirements(req); } }),
        React.createElement("div", null,
            React.createElement("label", null,
                "Should reveal kitty at end of game:",
                " ",
                React.createElement("select", { value: props.state.propagated.should_reveal_kitty_at_end_of_game
                        ? "show"
                        : "hide", onChange: props.setShouldRevealKittyAtEndOfGame },
                    React.createElement("option", { value: "hide" }, "Do not reveal contents of the kitty at the end of the game in chat"),
                    React.createElement("option", { value: "show" }, "Reveal contents of the kitty at the end of the game in chat")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Show player which defeats throw:",
                " ",
                React.createElement("select", { value: props.state.propagated.hide_throw_halting_player ? "hide" : "show", onChange: props.setHideThrowHaltingPlayer },
                    React.createElement("option", { value: "hide" }, "Hide the player who defeats a potential throw"),
                    React.createElement("option", { value: "show" }, "Show the player who defeats a potential throw")))),
        React.createElement("div", null,
            React.createElement("label", null,
                "Jacks variation:",
                " ",
                React.createElement("select", { value: props.state.propagated.jack_variation, onChange: props.setJackVariation },
                    React.createElement("option", { value: "SingleJack" }, "Winning the last trick with a single J will set the leader's team to rank 2"),
                    React.createElement("option", { value: "Disabled" }, "Disable the J variation"))))));
    return (React.createElement("div", null,
        React.createElement("label", null,
            "More game settings:",
            " ",
            React.createElement("button", { className: "normal", onClick: function (evt) {
                    evt.preventDefault();
                    setModalOpen(true);
                } }, "Open"),
            React.createElement(ReactModal, { isOpen: modalOpen, onRequestClose: function () { return setModalOpen(false); }, shouldCloseOnOverlayClick: true, shouldCloseOnEsc: true, style: { content: contentStyle } }, s))));
};
var Initialize = function (props) {
    var send = React.useContext(WebsocketContext).send;
    var _a = React.useState(false), showPicker = _a[0], setShowPicker = _a[1];
    var setGameMode = function (evt) {
        evt.preventDefault();
        if (evt.target.value === "Tractor") {
            send({ Action: { SetGameMode: "Tractor" } });
        }
        else {
            send({
                Action: {
                    SetGameMode: {
                        FindingFriends: {
                            num_friends: null,
                        },
                    },
                },
            });
        }
    };
    var setNumFriends = function (evt) {
        evt.preventDefault();
        if (evt.target.value === "") {
            send({
                Action: {
                    SetGameMode: {
                        FindingFriends: {
                            num_friends: null,
                        },
                    },
                },
            });
        }
        else {
            var num = parseInt(evt.target.value, 10);
            send({
                Action: {
                    SetGameMode: {
                        FindingFriends: {
                            num_friends: num,
                        },
                    },
                },
            });
        }
    };
    var onSelectString = function (action) {
        return function (evt) {
            var _a;
            evt.preventDefault();
            if (evt.target.value !== "") {
                send({ Action: (_a = {}, _a[action] = evt.target.value, _a) });
            }
        };
    };
    var onSelectStringDefault = function (action, defaultValue) {
        return function (evt) {
            var _a, _b;
            evt.preventDefault();
            if (evt.target.value !== "") {
                send({ Action: (_a = {}, _a[action] = evt.target.value, _a) });
            }
            else {
                send({ Action: (_b = {}, _b[action] = defaultValue, _b) });
            }
        };
    };
    var setFriendSelectionPolicy = onSelectString("SetFriendSelectionPolicy");
    var setMultipleJoinPolicy = onSelectString("SetMultipleJoinPolicy");
    var setFirstLandlordSelectionPolicy = onSelectString("SetFirstLandlordSelectionPolicy");
    var setBidPolicy = onSelectString("SetBidPolicy");
    var setBidReinforcementPolicy = onSelectString("SetBidReinforcementPolicy");
    var setJokerBidPolicy = onSelectString("SetJokerBidPolicy");
    var setKittyTheftPolicy = onSelectString("SetKittyTheftPolicy");
    var setKittyBidPolicy = onSelectString("SetKittyBidPolicy");
    var setTrickDrawPolicy = onSelectString("SetTrickDrawPolicy");
    var setThrowEvaluationPolicy = onSelectString("SetThrowEvaluationPolicy");
    var setPlayTakebackPolicy = onSelectString("SetPlayTakebackPolicy");
    var setGameShadowingPolicy = onSelectString("SetGameShadowingPolicy");
    var setGameStartPolicy = onSelectString("SetGameStartPolicy");
    var setBidTakebackPolicy = onSelectString("SetBidTakebackPolicy");
    var setGameVisibility = onSelectString("SetGameVisibility");
    var setShouldRevealKittyAtEndOfGame = function (evt) {
        evt.preventDefault();
        if (evt.target.value !== "") {
            send({
                Action: {
                    SetShouldRevealKittyAtEndOfGame: evt.target.value === "show",
                },
            });
        }
    };
    var setHideThrowHaltingPlayer = function (evt) {
        evt.preventDefault();
        if (evt.target.value !== "") {
            send({
                Action: {
                    SetHideThrowHaltingPlayer: evt.target.value === "hide",
                },
            });
        }
    };
    var setJackVariation = function (evt) {
        evt.preventDefault();
        if (evt.target.value !== "") {
            send({
                Action: {
                    SetJackVariation: evt.target.value,
                },
            });
        }
    };
    var setKittyPenalty = onSelectStringDefault("SetKittyPenalty", null);
    var setAdvancementPolicy = onSelectStringDefault("SetAdvancementPolicy", "Unrestricted");
    var setMaxRank = onSelectStringDefault("SetMaxRank", "NT");
    var setThrowPenalty = onSelectStringDefault("SetThrowPenalty", null);
    var setHideLandlordsPoints = function (evt) {
        evt.preventDefault();
        send({ Action: { SetHideLandlordsPoints: evt.target.value === "hide" } });
    };
    var setHidePlayedCards = function (evt) {
        evt.preventDefault();
        send({ Action: { SetHidePlayedCards: evt.target.value === "hide" } });
    };
    var startGame = function (evt) {
        evt.preventDefault();
        send({ Action: "StartGame" });
    };
    var setEmoji = function (emoji) {
        send({
            Action: {
                SetLandlordEmoji: emoji,
            },
        });
    };
    var modeAsString = props.state.propagated.game_mode === "Tractor"
        ? "Tractor"
        : "FindingFriends";
    var numFriends = props.state.propagated.game_mode === "Tractor" ||
        props.state.propagated.game_mode.FindingFriends.num_friends === null
        ? ""
        : props.state.propagated.game_mode.FindingFriends.num_friends;
    var decksEffective = props.state.propagated.num_decks !== undefined &&
        props.state.propagated.num_decks !== null &&
        props.state.propagated.num_decks > 0
        ? props.state.propagated.num_decks
        : Math.max(Math.floor(props.state.propagated.players.length / 2), 1);
    var decks = __spreadArray([], (props.state.propagated.special_decks || []), true);
    while (decks.length < decksEffective) {
        decks.push({
            exclude_big_joker: false,
            exclude_small_joker: false,
            min: "2",
        });
    }
    decks.length = decksEffective;
    var currentPlayer = props.state.propagated.players.find(function (p) { return p.name === props.name; });
    if (currentPlayer === undefined) {
        currentPlayer = props.state.propagated.observers.find(function (p) { return p.name === props.name; });
    }
    if (currentPlayer === undefined) {
        currentPlayer = {
            id: -1,
            name: props.name,
            level: "",
            metalevel: 0,
        };
    }
    var landlordIndex = props.state.propagated.players.findIndex(function (p) { return p.id === props.state.propagated.landlord; });
    var saveGameSettings = function (evt) {
        evt.preventDefault();
        localStorage.setItem("gameSettingsInLocalStorage", JSON.stringify(props.state.propagated));
    };
    var setGameSettings = function (gameSettings) {
        if (gameSettings !== null) {
            var kittySizeSet = false;
            var kittySize = null;
            for (var _i = 0, _a = Object.entries(gameSettings); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                switch (key) {
                    case "game_mode":
                        send({
                            Action: {
                                SetGameMode: value,
                            },
                        });
                        break;
                    case "num_decks":
                        send({
                            Action: {
                                SetNumDecks: value,
                            },
                        });
                        if (kittySizeSet) {
                            // reset the size again, as setting deck num resets kitty_size to default
                            send({
                                Action: {
                                    SetKittySize: kittySize,
                                },
                            });
                        }
                        break;
                    case "special_decks":
                        send({
                            Action: {
                                SetSpecialDecks: value,
                            },
                        });
                        break;
                    case "kitty_size":
                        send({
                            Action: {
                                SetKittySize: value,
                            },
                        });
                        kittySizeSet = true;
                        kittySize = value;
                        break;
                    case "friend_selection_policy":
                        send({
                            Action: {
                                SetFriendSelectionPolicy: value,
                            },
                        });
                        break;
                    case "multiple_join_policy":
                        send({
                            Action: {
                                SetMultipleJoinPolicy: value,
                            },
                        });
                        break;
                    case "first_landlord_selection_policy":
                        send({
                            Action: {
                                SetFirstLandlordSelectionPolicy: value,
                            },
                        });
                        break;
                    case "hide_landlord_points":
                        send({
                            Action: {
                                SetHideLandlordsPoints: value,
                            },
                        });
                        break;
                    case "hide_played_cards":
                        send({ Action: { SetHidePlayedCards: value } });
                        break;
                    case "advancement_policy":
                        send({
                            Action: {
                                SetAdvancementPolicy: value,
                            },
                        });
                        break;
                    case "max_rank":
                        send({
                            Action: {
                                SetMaxRank: value,
                            },
                        });
                        break;
                    case "kitty_bid_policy":
                        send({
                            Action: {
                                SetKittyBidPolicy: value,
                            },
                        });
                        break;
                    case "kitty_penalty":
                        send({
                            Action: {
                                SetKittyPenalty: value,
                            },
                        });
                        break;
                    case "kitty_theft_policy":
                        send({
                            Action: {
                                SetKittyTheftPolicy: value,
                            },
                        });
                        break;
                    case "throw_penalty":
                        send({
                            Action: {
                                SetThrowPenalty: value,
                            },
                        });
                        break;
                    case "trick_draw_policy":
                        send({
                            Action: {
                                SetTrickDrawPolicy: value,
                            },
                        });
                        break;
                    case "throw_evaluation_policy":
                        send({
                            Action: {
                                SetThrowEvaluationPolicy: value,
                            },
                        });
                        break;
                    case "landlord_emoji":
                        send({
                            Action: {
                                SetLandlordEmoji: value,
                            },
                        });
                        break;
                    case "bid_policy":
                        send({
                            Action: {
                                SetBidPolicy: value,
                            },
                        });
                        break;
                    case "bid_reinforcement_policy":
                        send({
                            Action: {
                                SetBidReinforcementPolicy: value,
                            },
                        });
                        break;
                    case "joker_bid_policy":
                        send({
                            Action: {
                                SetJokerBidPolicy: value,
                            },
                        });
                        break;
                    case "should_reveal_kitty_at_end_of_game":
                        send({
                            Action: {
                                SetShouldRevealKittyAtEndOfGame: value,
                            },
                        });
                        break;
                    case "hide_throw_halting_player":
                        send({ Action: { SetHideThrowHaltingPlayer: value } });
                        break;
                    case "set_jack_variation":
                        send({ Action: { SetJackVariation: value } });
                        break;
                    case "game_scoring_parameters":
                        send({
                            Action: {
                                SetGameScoringParameters: value,
                            },
                        });
                        break;
                    case "play_takeback_policy":
                        send({
                            Action: {
                                SetPlayTakebackPolicy: value,
                            },
                        });
                        break;
                    case "bid_takeback_policy":
                        send({
                            Action: {
                                SetBidTakebackPolicy: value,
                            },
                        });
                        break;
                    case "game_shadowing_policy":
                        send({
                            Action: {
                                SetGameShadowingPolicy: value,
                            },
                        });
                        break;
                    case "game_start_policy":
                        send({
                            Action: {
                                SetGameStartPolicy: value,
                            },
                        });
                        break;
                    case "tractor_requirements":
                        send({
                            Action: {
                                SetTractorRequirements: value,
                            },
                        });
                        break;
                    case "game_visibility":
                        send({
                            Action: {
                                SetGameVisibility: value,
                            },
                        });
                        break;
                }
            }
        }
    };
    var loadGameSettings = function (evt) {
        evt.preventDefault();
        var settings = localStorage.getItem("gameSettingsInLocalStorage");
        if (settings !== null) {
            var gameSettings_1;
            try {
                gameSettings_1 = JSON.parse(settings);
                var fetchAsync = function () { return __awaiter(void 0, void 0, void 0, function () {
                    var fetchResult, fetchJSON, combined;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, fetch("default_settings.json")];
                            case 1:
                                fetchResult = _a.sent();
                                return [4 /*yield*/, fetchResult.json()];
                            case 2:
                                fetchJSON = _a.sent();
                                combined = __assign(__assign({}, fetchJSON), gameSettings_1);
                                if (combined.bonus_level_policy !== undefined &&
                                    combined.game_scoring_parameters !== undefined &&
                                    combined.bonus_level_policy !==
                                        combined.game_scoring_parameters.bonus_level_policy) {
                                    combined.game_scoring_parameters.bonus_level_policy =
                                        combined.bonus_level_policy;
                                }
                                setGameSettings(combined);
                                return [2 /*return*/];
                        }
                    });
                }); };
                fetchAsync().catch(function (e) {
                    console.error(e);
                    localStorage.setItem("gameSettingsInLocalStorage", JSON.stringify(props.state.propagated));
                });
            }
            catch (_a) {
                localStorage.setItem("gameSettingsInLocalStorage", JSON.stringify(props.state.propagated));
            }
        }
    };
    var resetGameSettings = function (evt) {
        evt.preventDefault();
        var fetchAsync = function () { return __awaiter(void 0, void 0, void 0, function () {
            var fetchResult, fetchJSON;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("default_settings.json")];
                    case 1:
                        fetchResult = _a.sent();
                        return [4 /*yield*/, fetchResult.json()];
                    case 2:
                        fetchJSON = _a.sent();
                        setGameSettings(fetchJSON);
                        return [2 /*return*/];
                }
            });
        }); };
        fetchAsync().catch(function (e) { return console.error(e); });
    };
    return (React.createElement("div", null,
        React.createElement(Header, { gameMode: props.state.propagated.game_mode, chatLink: props.state.propagated.chat_link }),
        React.createElement(Players, { players: props.state.propagated.players, observers: props.state.propagated.observers, landlord: props.state.propagated.landlord, next: null, movable: true, name: props.name }),
        React.createElement("p", null,
            "Send link to other players to allow them to join the game:",
            " ",
            React.createElement("a", { href: window.location.href, target: "_blank", rel: "noreferrer" },
                React.createElement("code", null, window.location.href))),
        props.state.propagated.players.length >= 4 ? (React.createElement(React.Fragment, null,
            React.createElement("button", { className: "big", disabled: props.state.propagated.game_start_policy ===
                    "AllowLandlordOnly" &&
                    landlordIndex !== -1 &&
                    props.state.propagated.players[landlordIndex].name !== props.name, onClick: startGame }, "Start game"),
            React.createElement(ReadyCheck, null))) : (React.createElement("h2", null, "Waiting for players...")),
        React.createElement(RandomizePlayersButton, { players: props.state.propagated.players }, "Randomize player order"),
        React.createElement(Kicker, { players: props.state.propagated.players, onKick: function (playerId) { return send({ Kick: playerId }); } }),
        React.createElement("div", { className: "game-settings" },
            React.createElement("h3", null, "Game settings"),
            React.createElement("div", null,
                React.createElement("label", null,
                    "Game mode:",
                    " ",
                    React.createElement("select", { value: modeAsString, onChange: setGameMode },
                        React.createElement("option", { value: "Tractor" }, "\u5347\u7EA7 / Tractor"),
                        React.createElement("option", { value: "FindingFriends" }, "\u627E\u670B\u53CB / Finding Friends")))),
            React.createElement("div", null, props.state.propagated.game_mode !== "Tractor" ? (React.createElement("label", null,
                "Number of friends:",
                " ",
                React.createElement("select", { value: numFriends, onChange: setNumFriends },
                    React.createElement("option", { value: "" }, "default"),
                    ArrayUtils.range(Math.max(Math.floor(props.state.propagated.players.length / 2) - 1, 0), function (idx) { return (React.createElement("option", { value: idx + 1, key: idx }, idx + 1)); })))) : null),
            React.createElement(NumDecksSelector, { numPlayers: props.state.propagated.players.length, numDecks: props.state.propagated.num_decks, onChange: function (newNumDecks) {
                    return send({ Action: { SetNumDecks: newNumDecks } });
                } }),
            React.createElement(DeckSettings, { decks: decks, setSpecialDecks: function (d) { return send({ Action: { SetSpecialDecks: d } }); } }),
            React.createElement(KittySizeSelector, { numPlayers: props.state.propagated.players.length, decks: decks, kittySize: props.state.propagated.kitty_size, onChange: function (newKittySize) {
                    return send({ Action: { SetKittySize: newKittySize } });
                } }),
            React.createElement("div", null,
                React.createElement("label", null,
                    "Bids after cards are exchanged from the bottom:",
                    " ",
                    React.createElement("select", { value: props.state.propagated.kitty_theft_policy, onChange: setKittyTheftPolicy },
                        React.createElement("option", { value: "AllowKittyTheft" }, "Allowed (\u7092\u5730\u76AE)"),
                        React.createElement("option", { value: "NoKittyTheft" }, "Not allowed")))),
            React.createElement("div", null,
                React.createElement("label", null,
                    "Card protection policy:",
                    " ",
                    React.createElement("select", { value: props.state.propagated.trick_draw_policy, onChange: setTrickDrawPolicy },
                        React.createElement("option", { value: "NoProtections" }, "No protections"),
                        React.createElement("option", { value: "LongerTuplesProtected" }, "Longer tuple (triple) is protected from shorter (pair)"),
                        React.createElement("option", { value: "OnlyDrawTractorOnTractor" }, "Only tractors can draw tractors"),
                        React.createElement("option", { value: "LongerTuplesProtectedAndOnlyDrawTractorOnTractor" }, "Longer tuples are protected from shorter, and only tractors can draw tractors"),
                        React.createElement("option", { value: "NoFormatBasedDraw" }, "No format-based requirements (pairs do not draw pairs)")))),
            React.createElement("div", null,
                React.createElement("label", null,
                    "Multi-throw evaluation policy:",
                    " ",
                    React.createElement("select", { value: props.state.propagated.throw_evaluation_policy, onChange: setThrowEvaluationPolicy },
                        React.createElement("option", { value: "All" }, "Subsequent throw must beat all cards to win"),
                        React.createElement("option", { value: "Highest" }, "Subsequent throw must beat highest card to win"),
                        React.createElement("option", { value: "TrickUnitLength" }, "Subsequent throw must beat largest component to win")))),
            React.createElement(ScoringSettings, { state: props.state, decks: decks }),
            React.createElement(UncommonSettings, { state: props.state, numDecksEffective: decksEffective, setBidPolicy: setBidPolicy, setBidReinforcementPolicy: setBidReinforcementPolicy, setJokerBidPolicy: setJokerBidPolicy, setShouldRevealKittyAtEndOfGame: setShouldRevealKittyAtEndOfGame, setHideThrowHaltingPlayer: setHideThrowHaltingPlayer, setFirstLandlordSelectionPolicy: setFirstLandlordSelectionPolicy, setGameStartPolicy: setGameStartPolicy, setGameShadowingPolicy: setGameShadowingPolicy, setKittyBidPolicy: setKittyBidPolicy, setJackVariation: setJackVariation, setTractorRequirements: function (requirements) {
                    return send({ Action: { SetTractorRequirements: requirements } });
                } }),
            React.createElement(DifficultySettings, { state: props.state, setFriendSelectionPolicy: setFriendSelectionPolicy, setMultipleJoinPolicy: setMultipleJoinPolicy, setAdvancementPolicy: setAdvancementPolicy, setMaxRank: setMaxRank, setHideLandlordsPoints: setHideLandlordsPoints, setHidePlayedCards: setHidePlayedCards, setKittyPenalty: setKittyPenalty, setThrowPenalty: setThrowPenalty, setPlayTakebackPolicy: setPlayTakebackPolicy, setBidTakebackPolicy: setBidTakebackPolicy }),
            React.createElement("div", null,
                React.createElement("label", null,
                    "Game Visibility",
                    " ",
                    React.createElement("select", { value: props.state.propagated.game_visibility, onChange: setGameVisibility },
                        React.createElement("option", { value: "Unlisted" }, "Unlisted"),
                        React.createElement("option", { value: "Public" }, "Public")))),
            React.createElement("h3", null, "Continuation settings"),
            React.createElement(LandlordSelector, { players: props.state.propagated.players, landlordId: props.state.propagated.landlord, onChange: function (newLandlord) {
                    return send({ Action: { SetLandlord: newLandlord } });
                } }),
            React.createElement(RankSelector, { rank: currentPlayer.level, metaRank: currentPlayer.metalevel, onChangeRank: function (newRank) {
                    return send({ Action: { SetRank: newRank } });
                }, onChangeMetaRank: function (newMetaRank) {
                    return send({ Action: { SetMetaRank: newMetaRank } });
                } }),
            React.createElement("h3", null, "Misc settings"),
            React.createElement("div", null,
                React.createElement("label", null,
                    "Landlord label:",
                    " ",
                    props.state.propagated.landlord_emoji !== null &&
                        props.state.propagated.landlord_emoji !== undefined &&
                        props.state.propagated.landlord_emoji !== ""
                        ? props.state.propagated.landlord_emoji
                        : "当庄",
                    " ",
                    React.createElement("button", { className: "normal", onClick: function () {
                            setShowPicker(!showPicker);
                        } }, showPicker ? "Hide" : "Pick"),
                    React.createElement("button", { className: "normal", disabled: props.state.propagated.landlord_emoji == null, onClick: function () {
                            send({ Action: { SetLandlordEmoji: null } });
                        } }, "Default"),
                    showPicker ? (React.createElement(React.Suspense, { fallback: "..." },
                        React.createElement(Picker, { onEmojiClick: function (ecd) { return setEmoji(ecd.emoji); }, emojiStyle: EmojiStyle.NATIVE }))) : null)),
            React.createElement("div", null,
                React.createElement("label", null,
                    "Setting Management:",
                    React.createElement("button", { className: "normal", "data-tip": true, "data-for": "saveTip", onClick: saveGameSettings }, "Save"),
                    React.createElement(ReactTooltip, { id: "saveTip", place: "top", effect: "solid" }, "Save game settings"),
                    React.createElement("button", { className: "normal", "data-tip": true, "data-for": "loadTip", onClick: loadGameSettings }, "Load"),
                    React.createElement(ReactTooltip, { id: "loadTip", place: "top", effect: "solid" }, "Load saved game settings"),
                    React.createElement("button", { className: "normal", "data-tip": true, "data-for": "resetTip", onClick: resetGameSettings }, "Reset"),
                    React.createElement(ReactTooltip, { id: "resetTip", place: "top", effect: "solid" }, "Reset game settings to defaults"))))));
};
export default Initialize;

import * as React from "react";
import { SettingsContext } from "./AppStateProvider";
var DEFAULT_TITLE = "Play 升级 / Tractor / 找朋友 / Finding Friends online!";
var TitleHandler = function (props) {
    var settings = React.useContext(SettingsContext);
    React.useEffect(function () {
        if (props.playerName !== undefined &&
            props.playerName !== null &&
            settings.showPlayerName) {
            document.title = "".concat(props.playerName, " | ").concat(DEFAULT_TITLE);
        }
        else {
            document.title = DEFAULT_TITLE;
        }
    }, [props.playerName, settings.showPlayerName]);
    return React.createElement(React.Fragment, null);
};
export default TitleHandler;

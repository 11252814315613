var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { DEFAULT_POINT_CARD_ICON, DEFAULT_TRUMP_CARD_ICON, } from "./state/Settings";
import { CompactPicker } from "react-color";
import styled from "styled-components";
var Picker = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import("emoji-picker-react")];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); });
var Row = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: table-row;\n  line-height: 23px;\n"], ["\n  display: table-row;\n  line-height: 23px;\n"])));
var LabelCell = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: table-cell;\n  padding-right: 2em;\n"], ["\n  display: table-cell;\n  padding-right: 2em;\n"])));
var Cell = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: table-cell;\n"], ["\n  display: table-cell;\n"])));
var SettingsPane = function (props) {
    var settings = props.settings;
    var makeChangeHandler = function (partialSettings) { return function () {
        var newSettings = __assign(__assign({}, props.settings), partialSettings);
        props.onChangeSettings(newSettings);
    }; };
    var _a = React.useState(""), link = _a[0], setLink = _a[1];
    var setChatLink = function (event) {
        event.preventDefault();
        if (link.length > 0) {
            window.send({ Action: { SetChatLink: link } });
        }
        else {
            window.send({ Action: { SetChatLink: null } });
        }
        setLink("");
    };
    var editor = (React.createElement("div", { style: { marginBottom: "15px" } },
        React.createElement("input", { type: "text", style: { width: "150px" }, value: link, onChange: function (evt) {
                evt.preventDefault();
                setLink(evt.target.value);
            }, placeholder: "https://... link to voice chat" }),
        React.createElement("input", { type: "button", onClick: setChatLink, value: "set" })));
    return (React.createElement("div", { className: "settings" },
        React.createElement("div", { style: { display: "table" } },
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "four-color mode"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "four-color-mode", type: "checkbox", checked: settings.fourColor, onChange: makeChangeHandler({ fourColor: !settings.fourColor }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "dark mode"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "dark-mode", type: "checkbox", checked: settings.darkMode, onChange: makeChangeHandler({ darkMode: !settings.darkMode }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "use SVG cards"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "svg-cards", type: "checkbox", checked: settings.svgCards, onChange: makeChangeHandler({ svgCards: !settings.svgCards }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "always show card labels"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "show-card-labels", type: "checkbox", checked: settings.showCardLabels, onChange: makeChangeHandler({
                            showCardLabels: !settings.showCardLabels,
                        }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "icon on point cards"),
                React.createElement(Cell, null,
                    React.createElement(EmojiPicker, { value: settings.pointCardIcon, setEmoji: function (emoji) {
                            makeChangeHandler({
                                pointCardIcon: emoji,
                            })();
                        }, setDefault: makeChangeHandler({
                            pointCardIcon: DEFAULT_POINT_CARD_ICON,
                        }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "icon on trump cards"),
                React.createElement(Cell, null,
                    React.createElement(EmojiPicker, { value: settings.trumpCardIcon, setEmoji: function (emoji) {
                            makeChangeHandler({
                                trumpCardIcon: emoji,
                            })();
                        }, setDefault: makeChangeHandler({
                            trumpCardIcon: DEFAULT_TRUMP_CARD_ICON,
                        }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "show last trick"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "show-last-trick", type: "checkbox", checked: settings.showLastTrick, onChange: makeChangeHandler({
                            showLastTrick: !settings.showLastTrick,
                        }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "beep on turn"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "beep-on-turn", type: "checkbox", checked: settings.beepOnTurn, onChange: makeChangeHandler({ beepOnTurn: !settings.beepOnTurn }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "reverse card order (in hand)"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "reverse-card-order", type: "checkbox", checked: settings.reverseCardOrder, onChange: makeChangeHandler({
                            reverseCardOrder: !settings.reverseCardOrder,
                        }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "separate cards by effective suit (in hand)"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "separate-cards-by-suit", type: "checkbox", checked: settings.separateCardsBySuit, onChange: makeChangeHandler({
                            separateCardsBySuit: !settings.separateCardsBySuit,
                        }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "disable suit highlights"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "disable-suit-highlights", type: "checkbox", checked: settings.disableSuitHighlights, onChange: makeChangeHandler({
                            disableSuitHighlights: !settings.disableSuitHighlights,
                        }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "unset auto-play if winner changes"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "unset-auto-play-when-winner-changes", type: "checkbox", checked: settings.unsetAutoPlayWhenWinnerChanges, onChange: makeChangeHandler({
                            unsetAutoPlayWhenWinnerChanges: !settings.unsetAutoPlayWhenWinnerChanges,
                        }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "show tricks in player order"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "show-trick-in-player-order", type: "checkbox", checked: settings.showTrickInPlayerOrder, onChange: makeChangeHandler({
                            showTrickInPlayerOrder: !settings.showTrickInPlayerOrder,
                        }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "suit color overrides"),
                React.createElement(Cell, null, settings.svgCards ? ("disabled with SVG cards") : (React.createElement(SuitOverrides, { suitColors: settings.suitColorOverrides, setSuitColors: function (newOverrides) {
                        return props.onChangeSettings(__assign(__assign({}, props.settings), { suitColorOverrides: newOverrides }));
                    } })))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "play sound when drawing card"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "play-sound-when-drawing-card", type: "checkbox", checked: settings.playDrawCardSound, onChange: makeChangeHandler({
                            playDrawCardSound: !settings.playDrawCardSound,
                        }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "show debugging information"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "show-debug-info", type: "checkbox", checked: settings.showDebugInfo, onChange: makeChangeHandler({
                            showDebugInfo: !settings.showDebugInfo,
                        }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "show player name in title bar"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "show-player-name", type: "checkbox", checked: settings.showPlayerName, onChange: makeChangeHandler({
                            showPlayerName: !settings.showPlayerName,
                        }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "hide chat box"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "hide-chat-box", type: "checkbox", checked: settings.hideChatBox, onChange: makeChangeHandler({
                            hideChatBox: !settings.hideChatBox,
                        }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "show points bar above the game (rather than below)"),
                React.createElement(Cell, null,
                    React.createElement("input", { name: "show-points-above-game", type: "checkbox", checked: settings.showPointsAboveGame, onChange: makeChangeHandler({
                            showPointsAboveGame: !settings.showPointsAboveGame,
                        }) }))),
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "autodraw speed"),
                React.createElement(Cell, null,
                    React.createElement("select", { value: settings.autodrawSpeedMs !== null
                            ? settings.autodrawSpeedMs
                            : "", onChange: function (e) {
                            return makeChangeHandler({
                                autodrawSpeedMs: parseInt(e.target.value),
                            })();
                        } },
                        React.createElement("option", { value: "250" }, "default"),
                        React.createElement("option", { value: "500" }, "slow"),
                        React.createElement("option", { value: "10" }, "fast"))))),
        React.createElement("hr", null),
        React.createElement("div", { style: { display: "table" } },
            React.createElement(Row, null,
                React.createElement(LabelCell, null, "chat link"),
                React.createElement(Cell, null, editor)))));
};
var SuitOverrides = function (props) {
    var suits = ["♢", "♡", "♤", "♧", "🃟", "🃏"];
    var labels = ["♦", "♥", "♠", "♣", "LJ", "HJ"];
    return (React.createElement(React.Fragment, null,
        suits.map(function (suit, idx) { return (React.createElement(SuitColorPicker, { key: suit, suit: suit, label: labels[idx], suitColor: props.suitColors[suit], setSuitColor: function (color) {
                var n = __assign({}, props.suitColors);
                n[suit] = color;
                props.setSuitColors(n);
            } })); }),
        React.createElement("button", { className: "normal", onClick: function (evt) {
                evt.preventDefault();
                props.setSuitColors({});
            } }, "reset")));
};
var SuitColorPicker = function (props) {
    var _a = React.useState(false), showPicker = _a[0], setShowPicker = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: props.suit, style: { color: props.suitColor, cursor: "pointer" }, onClick: function () { return setShowPicker(true); } }, props.label),
        showPicker ? (React.createElement("div", { style: { position: "absolute" } },
            React.createElement("div", { style: { position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }, onClick: function () { return setShowPicker(false); } }),
            React.createElement(CompactPicker, { color: props.suitColor, onChangeComplete: function (c) { return props.setSuitColor(c.hex); } }))) : null));
};
var EmojiPicker = function (props) {
    var _a = React.useState(false), showPicker = _a[0], setShowPicker = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement("span", null, props.value),
        !showPicker && (React.createElement("button", { className: "normal", onClick: function () { return setShowPicker(true); } }, "pick")),
        showPicker && (React.createElement("button", { className: "normal", onClick: function () { return setShowPicker(false); } }, "hide")),
        React.createElement("button", { className: "normal", onClick: props.setDefault }, "reset"),
        props.value !== "" && (React.createElement("button", { className: "normal", onClick: function () { return props.setEmoji(""); } }, "no icon")),
        showPicker && (React.createElement(React.Suspense, { fallback: "..." },
            React.createElement(Picker, { onEmojiClick: function (emoji) {
                    props.setEmoji(emoji.emoji);
                } })))));
};
export default SettingsPane;
var templateObject_1, templateObject_2, templateObject_3;

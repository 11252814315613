var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import beep from "./beep";
var truncate = function (length) {
    return function (array) {
        if (array.length > length) {
            return array.slice(array.length - length);
        }
        else {
            return array;
        }
    };
};
var truncateMessages = truncate(300);
var messageHandler = function (state, message) {
    if ("Message" in message) {
        return { messages: truncateMessages(__spreadArray(__spreadArray([], state.messages, true), [message.Message], false)) };
    }
    else {
        return null;
    }
};
var broadcastHandler = function (state, message) {
    if ("Broadcast" in message) {
        var newMessage = {
            from: "GAME",
            message: message.Broadcast.message,
            data: message.Broadcast.data,
            from_game: true,
        };
        return { messages: truncateMessages(__spreadArray(__spreadArray([], state.messages, true), [newMessage], false)) };
    }
    else {
        return null;
    }
};
var errorHandler = function (state, message) {
    if ("Error" in message) {
        return { errors: __spreadArray(__spreadArray([], state.errors, true), [message.Error], false) };
    }
    else {
        return null;
    }
};
var stateHandler = function (_, message) {
    if ("State" in message) {
        return { gameState: message.State.state };
    }
    else {
        return null;
    }
};
var headerMessageHandler = function (_, message) {
    if ("Header" in message) {
        return { headerMessages: message.Header.messages };
    }
    else {
        return null;
    }
};
var lastBeeped = performance.now();
var beepHandler = function (message) {
    if ("Beep" in message) {
        var now = performance.now();
        // Rate-limit beeps to prevent annoyance.
        if (now - lastBeeped >= 1000) {
            beep(3, 261.63, 200);
            lastBeeped = now;
        }
    }
};
var lastReadyChecked = performance.now();
var readyCheckHandler = function (message, send) {
    if ("ReadyCheck" in message) {
        var now = performance.now();
        // Rate-limit beeps to prevent annoyance.
        if (now - lastReadyChecked >= 1000) {
            beep(3, 261.63, 200);
            lastReadyChecked = now;
            if (confirm("Are you ready to start the game?")) {
                send("Ready");
            }
        }
    }
};
var gameFinishedHandler = function (state, message) {
    if ("Broadcast" in message &&
        message.Broadcast.data.variant.type === "GameFinished") {
        var result = message.Broadcast.data.variant.result;
        var updates = {};
        if (state.name in result) {
            var ownResult = result[state.name];
            var gameStatistics = state.gameStatistics;
            var newGameStatistics = __assign({}, gameStatistics);
            newGameStatistics.gamesPlayed++;
            if (ownResult.is_defending) {
                newGameStatistics.gamesPlayedAsDefending++;
                if (ownResult.is_landlord) {
                    newGameStatistics.gamesPlayedAsLandlord++;
                }
            }
            if (ownResult.won_game) {
                newGameStatistics.gamesWon++;
                if (ownResult.is_defending) {
                    newGameStatistics.gamesWonAsDefending++;
                    if (ownResult.is_landlord) {
                        newGameStatistics.gamesWonAsLandlord++;
                    }
                }
            }
            newGameStatistics.ranksUp += ownResult.ranks_up;
            updates.gameStatistics = newGameStatistics;
        }
        var gameWinners = Object.entries(result)
            .filter(function (r) { return r[1].confetti; })
            .map(function (r) { return r[0]; });
        if (gameWinners.length > 0) {
            var group = gameWinners
                .join(", ")
                .replace(/, ((?:.(?!, ))+)$/, " and $1");
            updates.confetti = "".concat(group, " successfully defended on the final level!");
        }
        if (updates.gameStatistics !== undefined ||
            updates.confetti !== undefined) {
            return updates;
        }
    }
    return null;
};
var allHandlers = [
    messageHandler,
    broadcastHandler,
    errorHandler,
    stateHandler,
    headerMessageHandler,
    gameFinishedHandler,
];
var composedHandlers = function (state, message, send) {
    var partials = {};
    allHandlers.forEach(function (h) {
        var partial = h(state, message);
        partials = __assign(__assign({}, partials), partial);
        state = __assign(__assign({}, state), partial);
    });
    beepHandler(message);
    readyCheckHandler(message, send);
    return partials;
};
export default composedHandlers;

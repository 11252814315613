import * as React from "react";
var Kicker = function (props) {
    var _a = React.useState(null), selection = _a[0], setSelection = _a[1];
    var handleChange = function (e) {
        setSelection(e.target.value === "" ? null : parseInt(e.target.value, 10));
    };
    return (React.createElement("div", { className: "kicker" },
        React.createElement("label", null,
            "Kick player:",
            " ",
            React.createElement("select", { value: selection === null ? "" : selection, onChange: handleChange },
                React.createElement("option", { value: "" }),
                props.players.map(function (player) { return (React.createElement("option", { value: player.id, key: player.id }, player.name)); })),
            React.createElement("button", { className: "normal", onClick: function () {
                    if (selection) {
                        props.onKick(selection);
                    }
                }, disabled: selection === null }, "Kick"))));
};
export default Kicker;

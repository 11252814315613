import * as React from "react";
import classNames from "classnames";
import Card from "./Card";
var LabeledPlay = function (props) {
    var className = classNames("label", {
        next: props.next !== undefined &&
            props.next !== null &&
            props.id === props.next,
    });
    var cards = (props.cards || []).map(function (card, idx) { return (React.createElement(Card, { card: card, key: idx, trump: props.trump, collapseRight: idx !== (props.cards || []).length - 1 })); });
    var groupedCards = props.groupedCards !== undefined
        ? props.groupedCards.map(function (c, gidx) { return (React.createElement("div", { className: "card-group", key: gidx }, c.map(function (card, idx) { return (React.createElement(Card, { trump: props.trump, card: card, key: "".concat(gidx, "-").concat(idx), collapseRight: idx !== c.length - 1 })); }))); })
        : cards;
    return (React.createElement("div", { className: classNames("labeled-play", props.className, {
            clickable: props.onClick !== undefined,
        }), onClick: props.onClick !== undefined
            ? function (evt) {
                evt.preventDefault();
                if (props.onClick) {
                    props.onClick();
                }
            }
            : undefined },
        React.createElement("div", { className: "play" }, groupedCards),
        props.moreCards !== undefined && props.moreCards.length > 0 ? (React.createElement("div", { className: "play more" }, props.moreCards.map(function (card, idx) { return (React.createElement(Card, { trump: props.trump, card: card, key: idx, smaller: true, collapseRight: props.moreCards && idx !== props.moreCards.length - 1 })); }))) : null,
        React.createElement("div", { className: className }, props.label)));
};
export default LabeledPlay;

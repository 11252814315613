import * as React from "react";
import ReactModal from "react-modal";
import IconButton from "./IconButton";
import Gear from "./icons/Gear";
import SettingsPane from "./SettingsPane";
import ReactTooltip from "react-tooltip";
import { AppStateContext } from "./AppStateProvider";
var contentStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "80%",
    transform: "translate(-50%, -50%)",
};
var SettingsButton = function () {
    var _a = React.useState(false), modalOpen = _a[0], setModalOpen = _a[1];
    var _b = React.useContext(AppStateContext), state = _b.state, updateState = _b.updateState;
    return (React.createElement(React.Fragment, null,
        React.createElement(ReactTooltip, { id: "settingsTip", place: "top", effect: "solid" }, "Change user interface settings"),
        React.createElement(IconButton, { onClick: function () { return setModalOpen(true); }, "data-tip": true, "data-for": "settingsTip" },
            React.createElement(Gear, { width: "2em" })),
        React.createElement(ReactModal, { isOpen: modalOpen, onRequestClose: function () { return setModalOpen(false); }, shouldCloseOnOverlayClick: true, shouldCloseOnEsc: true, style: { content: contentStyle } },
            React.createElement(SettingsPane, { settings: state.settings, onChangeSettings: function (settings) { return updateState({ settings: settings }); } }))));
};
export default SettingsButton;

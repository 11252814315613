export var localStorageState = function (key, extractor, serializer) {
    return {
        loadDefault: function () { return extractor(window.localStorage.getItem(key)); },
        persist: function (before, after) {
            window.localStorage.setItem(key, serializer(after));
        },
    };
};
export var booleanLocalStorageState = function (key, defaultValue) {
    if (defaultValue === void 0) { defaultValue = false; }
    return localStorageState(key, function (value) { return value === "on" || (value == null && defaultValue); }, function (state) { return (state ? "on" : "off"); });
};
export var stringLocalStorageState = function (key, defaultValue) {
    if (defaultValue === void 0) { defaultValue = ""; }
    return localStorageState(key, function (value) { return (typeof value === "string" ? value : defaultValue); }, function (state) { return state; });
};
export var numberLocalStorageState = function (key, defaultValue) {
    if (defaultValue === void 0) { defaultValue = 0; }
    return localStorageState(key, function (value) {
        return value != null && !isNaN(value) ? parseInt(value, 10) : defaultValue;
    }, function (state) { return state; });
};
export var nullableNumberLocalStorageState = function (key, defaultValue) {
    if (defaultValue === void 0) { defaultValue = 0; }
    return localStorageState(key, function (value) {
        return value != null && !isNaN(value) ? parseInt(value, 10) : defaultValue;
    }, function (state) { return state; });
};
export function JSONLocalStorageState(key, defaultValue) {
    return localStorageState(key, function (value) {
        try {
            var val = JSON.parse(value);
            if (val !== undefined && val !== null) {
                return val;
            }
            else {
                return defaultValue;
            }
        }
        catch (_a) {
            return defaultValue;
        }
    }, function (state) { return JSON.stringify(state); });
}

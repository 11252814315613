var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import classNames from "classnames";
import Card from "./Card";
import ArrayUtils from "./util/array";
import WasmContext from "./WasmContext";
import { SettingsContext } from "./AppStateProvider";
var Cards = function (props) {
    var _a = React.useState(null), highlightedSuit = _a[0], setHighlightedSuit = _a[1];
    var hands = props.hands, selectedCards = props.selectedCards, notifyEmpty = props.notifyEmpty;
    var sortAndGroupCards = React.useContext(WasmContext).sortAndGroupCards;
    var _b = React.useContext(SettingsContext), separateCardsBySuit = _b.separateCardsBySuit, disableSuitHighlights = _b.disableSuitHighlights, reverseCardOrder = _b.reverseCardOrder;
    var handleSelect = function (card) { return function () {
        if (props.onCardClick !== undefined) {
            props.onCardClick(card);
        }
        if (selectedCards !== undefined && props.onSelect !== undefined) {
            props.onSelect(__spreadArray(__spreadArray([], selectedCards, true), [card], false));
        }
    }; };
    var handleUnselect = function (card) { return function () {
        if (selectedCards !== undefined) {
            var index = selectedCards.indexOf(card);
            if (index >= 0 && props.onSelect) {
                props.onSelect(ArrayUtils.minus(selectedCards, [card]));
            }
        }
    }; };
    var cardsInHand = props.playerId in hands.hands
        ? Object.entries(hands.hands[props.playerId]).flatMap(function (_a) {
            var c = _a[0], ct = _a[1];
            return Array(ct).fill(c);
        })
        : [];
    var unselected = selectedCards === undefined
        ? cardsInHand
        : ArrayUtils.minus(cardsInHand, selectedCards);
    var selectedCardGroups = props.selectedCards !== undefined
        ? sortAndGroupCards({
            cards: props.selectedCards,
            trump: props.trump,
        }).map(function (g) {
            return g.cards.map(function (c) { return ({
                card: c,
                suit: g.suit,
            }); });
        })
        : [];
    var unselectedCardGroups = sortAndGroupCards({
        cards: unselected,
        trump: props.trump,
    }).map(function (g) {
        return g.cards.map(function (c) { return ({
            card: c,
            suit: g.suit,
        }); });
    });
    if (!separateCardsBySuit) {
        selectedCardGroups = [selectedCardGroups.flatMap(function (g) { return g; })];
        unselectedCardGroups = [unselectedCardGroups.flatMap(function (g) { return g; })];
    }
    if (reverseCardOrder) {
        unselectedCardGroups.reverse();
        unselectedCardGroups.forEach(function (g) { return g.reverse(); });
    }
    return (React.createElement("div", { className: "hand" },
        props.selectedCards !== undefined ? (React.createElement("div", { className: "selected-cards" },
            selectedCardGroups.map(function (g, gidx) { return (React.createElement("div", { style: { display: "inline-block" }, key: gidx }, g.map(function (c, idx) { return (React.createElement(Card, { key: "".concat(gidx, "-").concat(idx), onClick: handleUnselect(c.card), trump: props.trump, card: c.card, collapseRight: idx !== g.length - 1 })); }))); }),
            props.selectedCards.length === 0 && (React.createElement(Card, { card: "\uD83C\uDCA0", trump: props.trump, className: classNames({ notify: notifyEmpty }) })))) : null,
        React.createElement("div", { className: classNames("unselected-cards", {
                unclickable: props.onSelect === undefined && props.onCardClick === undefined,
            }) },
            unselectedCardGroups.map(function (g, gidx) { return (React.createElement("div", { style: { display: "inline-block" }, key: gidx }, g.map(function (c, idx) { return (React.createElement(Card, { key: "".concat(gidx, "-").concat(idx), className: classNames(!disableSuitHighlights && highlightedSuit === c.suit
                    ? "highlighted"
                    : null), onClick: handleSelect(c.card), card: c.card, collapseRight: idx !== g.length - 1, trump: props.trump, onMouseEnter: function (_) { return setHighlightedSuit(c.suit); }, onMouseLeave: function (_) { return setHighlightedSuit(null); } })); }))); }),
            unselectedCardGroups.length === 0 && (React.createElement(Card, { trump: props.trump, card: "\uD83C\uDCA0" })))));
};
export default Cards;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import classNames from "classnames";
import Errors from "./Errors";
import Initialize from "./Initialize";
import Draw from "./Draw";
import Exchange from "./Exchange";
import JoinRoom from "./JoinRoom";
import { AppStateContext } from "./AppStateProvider";
import { TimerContext } from "./TimerProvider";
import Credits from "./Credits";
import Chat from "./Chat";
import Play from "./Play";
import DebugInfo from "./DebugInfo";
import TitleHandler from "./TitleHandler";
import ResetButton from "./ResetButton";
var Confetti = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import("./Confetti")];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); });
var Root = function () {
    var _a = React.useContext(AppStateContext), state = _a.state, updateState = _a.updateState;
    var timerContext = React.useContext(TimerContext);
    var _b = React.useState([]), previousHeaderMessages = _b[0], setPreviousHeaderMessages = _b[1];
    var _c = React.useState(state.headerMessages.length > 0), showHeaderMessages = _c[0], setShowHeaderMessages = _c[1];
    React.useEffect(function () {
        if (state.headerMessages.length > 0 &&
            (previousHeaderMessages.length !== state.headerMessages.length ||
                !previousHeaderMessages.every(function (m, i) { return state.headerMessages[i] === m; }))) {
            setShowHeaderMessages(true);
        }
        else if (state.headerMessages.length === 0) {
            setShowHeaderMessages(false);
        }
        setPreviousHeaderMessages(state.headerMessages);
    }, [state.headerMessages]);
    React.useEffect(function () {
        if (state.settings.darkMode) {
            document.body.classList.add("dark-mode");
        }
        else {
            document.body.classList.remove("dark-mode");
        }
        return function () {
            document.body.classList.remove("dark-mode");
        };
    }, [state.settings.darkMode]);
    var headerMessages = showHeaderMessages ? (React.createElement("div", { className: "header-message", onClick: function () { return setShowHeaderMessages(false); } }, state.headerMessages.map(function (msg, idx) { return (React.createElement("p", { key: idx }, msg)); }))) : null;
    if (state.connected) {
        if (state.gameState === null || state.roomName.length !== 16) {
            return (React.createElement("div", null,
                headerMessages,
                React.createElement(Errors, { errors: state.errors }),
                React.createElement("div", { className: "game" },
                    React.createElement("h1", null,
                        "\u5347\u7EA7 / ",
                        React.createElement("span", { className: "red" }, "Tractor"),
                        " / \u627E\u670B\u53CB /",
                        " ",
                        React.createElement("span", { className: "red" }, "Finding Friends")),
                    React.createElement(JoinRoom, { name: state.name, room_name: state.roomName, setName: function (name) { return updateState({ name: name }); }, setRoomName: function (roomName) {
                            updateState({ roomName: roomName });
                            window.location.hash = roomName;
                        } })),
                React.createElement("hr", null),
                React.createElement(Credits, null),
                React.createElement(TitleHandler, { playerName: state.name })));
        }
        else {
            return (React.createElement("div", { className: classNames(state.settings.fourColor ? "four-color" : null, state.settings.showCardLabels ? "always-show-labels" : null, state.settings.hideChatBox ? "hide-chat-box" : null) },
                headerMessages,
                React.createElement(Errors, { errors: state.errors }),
                state.confetti !== null ? (React.createElement(React.Suspense, { fallback: null },
                    React.createElement(Confetti, { confetti: state.confetti, clearConfetti: function () { return updateState({ confetti: null }); } }))) : null,
                React.createElement("div", { className: "game" },
                    "Initialize" in state.gameState ? null : (React.createElement(ResetButton, { state: state.gameState, name: state.name })),
                    "Initialize" in state.gameState ? (React.createElement(Initialize, { state: state.gameState.Initialize, name: state.name })) : null,
                    "Draw" in state.gameState ? (React.createElement(Draw, { state: state.gameState.Draw, playDrawCardSound: state.settings.playDrawCardSound, autodrawSpeedMs: state.settings.autodrawSpeedMs, name: state.name, setTimeout: timerContext.setTimeout, clearTimeout: timerContext.clearTimeout })) : null,
                    "Exchange" in state.gameState ? (React.createElement(Exchange, { state: state.gameState.Exchange, name: state.name })) : null,
                    "Play" in state.gameState ? (React.createElement(Play, { playPhase: state.gameState.Play, name: state.name, showLastTrick: state.settings.showLastTrick, unsetAutoPlayWhenWinnerChanges: state.settings.unsetAutoPlayWhenWinnerChanges, showTrickInPlayerOrder: state.settings.showTrickInPlayerOrder, beepOnTurn: state.settings.beepOnTurn })) : null,
                    state.settings.showDebugInfo ? React.createElement(DebugInfo, null) : null),
                React.createElement(Chat, { messages: state.messages }),
                React.createElement("hr", null),
                React.createElement(Credits, null),
                React.createElement(TitleHandler, { playerName: state.name })));
        }
    }
    else if (state.everConnected) {
        return (React.createElement(React.Fragment, null,
            React.createElement("p", null, "It looks like you got disconnected from the server, please refresh! If the game is still ongoing, you should be able to re-join with the same name and pick up where you left off.")));
    }
    else {
        return (React.createElement("div", null,
            React.createElement("div", { className: "game" },
                React.createElement("h1", null,
                    "\u5347\u7EA7 / ",
                    React.createElement("span", { className: "red" }, "Tractor"),
                    " / \u627E\u670B\u53CB /",
                    " ",
                    React.createElement("span", { className: "red" }, "Finding Friends")),
                React.createElement("p", null, "Welcome! This website helps you play \u5347\u7EA7 / Tractor / \u627E\u670B\u53CB / Finding Friends with other people online."),
                React.createElement("p", null,
                    "If you're not familiar with the rules, check them out",
                    " ",
                    React.createElement("a", { href: "rules.html" }, "here"),
                    "!"),
                React.createElement("p", null, "Connecting to the server...")),
            React.createElement("hr", null),
            React.createElement(Credits, null),
            React.createElement(TitleHandler, { playerName: state.name })));
    }
};
export default Root;

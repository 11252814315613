import * as React from "react";
import ProgressBar from "./ProgressBar";
import ArrayUtils from "./util/array";
import ObjectUtils from "./util/object";
import LabeledPlay from "./LabeledPlay";
import classNames from "classnames";
import { cardLookup } from "./util/cardHelpers";
import WasmContext from "./WasmContext";
import { SettingsContext } from "./AppStateProvider";
export var calculatePoints = function (players, landlordTeam, points, penalties) {
    var pointsPerPlayer = ObjectUtils.mapValues(points, function (cards) {
        return ArrayUtils.sum(cards.map(function (card) { return cardLookup[card].points; }));
    });
    var totalPointsPlayed = ArrayUtils.sum(Object.values(pointsPerPlayer));
    var nonLandlordPoints = ArrayUtils.sum(players
        .filter(function (p) { return !landlordTeam.includes(p.id); })
        .map(function (p) { return pointsPerPlayer[p.id]; }));
    var nonLandlordPointsWithPenalties = nonLandlordPoints;
    players.forEach(function (p) {
        var penalty = penalties[p.id];
        if (penalty > 0) {
            if (landlordTeam.includes(p.id)) {
                nonLandlordPointsWithPenalties += penalty;
            }
            else {
                nonLandlordPointsWithPenalties -= penalty;
            }
        }
    });
    return {
        nonLandlordPoints: nonLandlordPoints,
        nonLandlordPointsWithPenalties: nonLandlordPointsWithPenalties,
        totalPointsPlayed: totalPointsPlayed,
    };
};
var Points = function (props) {
    var pointsPerPlayer = ObjectUtils.mapValues(props.points, function (cards) {
        return ArrayUtils.sum(cards.map(function (card) { return cardLookup[card].points; }));
    });
    var settings = React.useContext(SettingsContext);
    var _a = React.useContext(WasmContext), computeScore = _a.computeScore, explainScoring = _a.explainScoring;
    var _b = calculatePoints(props.players, props.landlordTeam, props.points, props.penalties), totalPointsPlayed = _b.totalPointsPlayed, nonLandlordPointsWithPenalties = _b.nonLandlordPointsWithPenalties, nonLandlordPoints = _b.nonLandlordPoints;
    var penaltyDelta = nonLandlordPointsWithPenalties - nonLandlordPoints;
    var _c = computeScore({
        params: props.gameScoringParameters,
        decks: props.decks,
        smaller_landlord_team_size: props.smallerTeamSize,
        non_landlord_points: nonLandlordPointsWithPenalties,
    }), score = _c.score, nextThreshold = _c.next_threshold;
    var playerPointElements = props.players.map(function (player) {
        var onLandlordTeam = props.landlordTeam.includes(player.id);
        var cards = props.points[player.id].length > 0 ? props.points[player.id] : ["🂠"];
        var penalty = player.id in props.penalties ? props.penalties[player.id] : 0;
        if (props.hideLandlordPoints && onLandlordTeam) {
            return null;
        }
        else {
            return (React.createElement(LabeledPlay, { key: player.id, trump: props.trump, className: classNames({ landlord: onLandlordTeam }), label: "".concat(player.name, ": ").concat(pointsPerPlayer[player.id] - penalty, "\u5206"), cards: cards }));
        }
    });
    // TODO: Pass the landlord as a Player object instead of numeric ID
    var landlord = props.players.find(function (p) { return p.id === props.landlord; });
    var thresholdStr = "";
    if (score.landlord_won) {
        thresholdStr = "".concat(landlord === null || landlord === void 0 ? void 0 : landlord.name, "'s team will go up ").concat(score.landlord_delta, " level").concat(score.landlord_delta === 1 ? "" : "s");
        if (score.landlord_bonus) {
            thresholdStr += ", including a small-team bonus";
        }
    }
    else if (score.non_landlord_delta === 0) {
        thresholdStr = "Neither team will go up a level";
    }
    else {
        thresholdStr = "The attacking team will go up ".concat(score.non_landlord_delta, " level").concat(score.non_landlord_delta === 1 ? "" : "s");
    }
    thresholdStr += " (next threshold: ".concat(nextThreshold, "\u5206)");
    var _d = explainScoring({
        params: props.gameScoringParameters,
        smaller_landlord_team_size: props.smallerTeamSize,
        decks: props.decks,
    }), scoreTransitions = _d.results, totalPoints = _d.total_points;
    return (React.createElement("div", { className: "points" },
        React.createElement("h2", null, "Points"),
        !settings.showPointsAboveGame && (React.createElement(ProgressBar, { checkpoints: scoreTransitions
                .map(function (transition) { return transition.point_threshold; })
                .filter(function (threshold) { return threshold >= 10 && threshold < totalPoints; }), totalPoints: totalPoints, landlordPoints: totalPointsPlayed - nonLandlordPoints, challengerPoints: nonLandlordPointsWithPenalties, hideLandlordPoints: props.hideLandlordPoints })),
        React.createElement("p", null,
            penaltyDelta === 0
                ? nonLandlordPoints
                : "".concat(nonLandlordPoints, " + ").concat(penaltyDelta),
            "\u5206",
            props.hideLandlordPoints ? null : " / ".concat(totalPointsPlayed, "\u5206"),
            " stolen from ", landlord === null || landlord === void 0 ? void 0 :
            landlord.name,
            "'s team. ",
            thresholdStr),
        playerPointElements));
};
export var ProgressBarDisplay = function (props) {
    var explainScoring = React.useContext(WasmContext).explainScoring;
    var _a = calculatePoints(props.players, props.landlordTeam, props.points, props.penalties), totalPointsPlayed = _a.totalPointsPlayed, nonLandlordPointsWithPenalties = _a.nonLandlordPointsWithPenalties, nonLandlordPoints = _a.nonLandlordPoints;
    var _b = explainScoring({
        params: props.gameScoringParameters,
        smaller_landlord_team_size: props.smallerTeamSize,
        decks: props.decks,
    }), scoreTransitions = _b.results, totalPoints = _b.total_points;
    return (React.createElement(ProgressBar, { checkpoints: scoreTransitions
            .map(function (transition) { return transition.point_threshold; })
            .filter(function (threshold) { return threshold >= 10 && threshold < totalPoints; }), totalPoints: totalPoints, landlordPoints: totalPointsPlayed - nonLandlordPoints, challengerPoints: nonLandlordPointsWithPenalties, hideLandlordPoints: props.hideLandlordPoints }));
};
export default Points;

import * as React from "react";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import LabeledPlay from "./LabeledPlay";
import ArrayUtils from "./util/array";
var TrickE = function (props) {
    var namesById = ArrayUtils.mapObject(props.players, function (p) { return [
        String(p.id),
        p.name,
    ]; });
    var blankCards = props.trick.played_cards.length > 0
        ? Array(props.trick.played_cards[0].cards.length).fill("🂠")
        : ["🂠"];
    var betterPlayer = props.trick.played_cards.length > 0
        ? props.trick.played_cards[0].better_player
        : null;
    var playedByID = {};
    var cardsFromMappingByID = {};
    var playOrder = [];
    props.trick.played_cards.forEach(function (played, idx) {
        playOrder.push(played.id);
        playedByID[played.id] = played;
        var m = props.trick.played_card_mappings
            ? props.trick.played_card_mappings[idx]
            : undefined;
        if (m !== undefined && m !== null && m.length > 0) {
            // We should coalesce blocks of `Repeated` of count 1 together, since
            // that displays more nicely.
            var mapping_1 = [];
            var singles_1 = [];
            m.forEach(function (mm) {
                if ("Repeated" in mm && mm.Repeated.count === 1) {
                    singles_1.push(mm.Repeated.card.card);
                }
                else if ("Repeated" in mm) {
                    mapping_1.push(ArrayUtils.range(mm.Repeated.count, function (_) { return mm.Repeated.card.card; }));
                }
                else if ("Tractor" in mm) {
                    mapping_1.push(mm.Tractor.members.flatMap(function (mmm) {
                        return ArrayUtils.range(mm.Tractor.count, function (_) { return mmm.card; });
                    }));
                }
            });
            mapping_1.push(singles_1);
            cardsFromMappingByID[played.id] = mapping_1;
        }
    });
    if (props.showTrickInPlayerOrder) {
        playOrder = props.players.map(function (p) { return p.id; });
    }
    else {
        props.trick.player_queue.forEach(function (id) { return playOrder.push(id); });
    }
    return (React.createElement("div", { className: "trick" }, playOrder.map(function (id) {
        var _a, _b;
        var winning = props.trick.current_winner === id;
        var better = betterPlayer === id;
        var cards = id in playedByID ? playedByID[id].cards : blankCards;
        var suffix = winning ? (React.createElement(React.Fragment, null,
            " ",
            React.createElement(ReactTooltip, { id: "winningTip", place: "bottom", effect: "solid" }, "Current winner of trick"),
            React.createElement("span", { "data-tip": true, "data-for": "winningTip" },
                "(",
                React.createElement("code", null, "!"),
                ")"))) : better ? (React.createElement(React.Fragment, null,
            " ",
            React.createElement(ReactTooltip, { id: "betterTip", place: "bottom", effect: "solid" }, "First player who can prevent the attempted throw"),
            React.createElement("span", { "data-tip": true, "data-for": "betterTip" },
                "(",
                React.createElement("code", null, "-"),
                ")"))) : (React.createElement(React.Fragment, null));
        var className = classNames(winning
            ? "winning"
            : props.trick.player_queue[0] === id
                ? "notify"
                : "", {
            landlord: id === props.landlord || ((_a = props.landlords_team) === null || _a === void 0 ? void 0 : _a.includes(id)),
        });
        return (React.createElement(LabeledPlay, { key: id, id: id, label: React.createElement(React.Fragment, null,
                namesById[id] +
                    (id === props.landlord ? " " + props.landlord_suffix : ""),
                suffix), className: className, groupedCards: cardsFromMappingByID[id], cards: cards, trump: props.trick.trump, next: props.next, moreCards: (_b = playedByID[id]) === null || _b === void 0 ? void 0 : _b.bad_throw_cards }));
    })));
};
export default TrickE;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import Select from "react-select";
import ArrayUtils from "./util/array";
import preloadedCards from "./preloadedCards";
import InlineCard from "./InlineCard";
import { cardLookup } from "./util/cardHelpers";
var FriendSelect = function (props) {
    var handleChange = function (transform) { return function (value) {
        props.onChange(__assign({ card: props.friend.card, initial_skip: props.friend.initial_skip }, transform(value)));
    }; };
    var handleCardChange = handleChange(function (select) { return ({
        card: select.value,
    }); });
    var handleOrdinalChange = handleChange(function (select) { return ({
        initial_skip: parseInt(select.value, 10),
    }); });
    var rank = "Standard" in props.trump
        ? props.trump.Standard.number
        : props.trump.NoTrump.number;
    var cardOptions = [];
    var currentValue = {};
    if (props.friend.card !== "") {
        var c = cardLookup[props.friend.card];
        currentValue.label = "".concat(c.number).concat(c.typ);
        currentValue.value = c.value;
    }
    var notTrumpFilter = function (c) {
        var _a;
        return (c.number !== null &&
            c.number !== rank &&
            (!("Standard" in props.trump) || c.typ !== ((_a = props.trump.Standard) === null || _a === void 0 ? void 0 : _a.suit)));
    };
    var policyFilters = {
        PointCardNotAllowed: function (c) {
            return (notTrumpFilter(c) &&
                (c.points === 0 || (rank === "A" && c.number === "K")));
        },
        HighestCardNotAllowed: function (c) {
            return (notTrumpFilter(c) &&
                ((rank !== "A" && c.number !== "A") ||
                    (rank === "A" && c.number !== "K")));
        },
        Unrestricted: function (c) { return notTrumpFilter(c); },
        TrumpsIncluded: function (_) { return true; },
    };
    var policyFilter = policyFilters[props.friend_selection_policy];
    preloadedCards
        .filter(function (c) { return policyFilter(c); })
        .forEach(function (c) {
        return cardOptions.push({
            label: "".concat(c.number).concat(c.typ),
            value: c.value,
        });
    });
    return (React.createElement("div", { className: "friend-select" },
        React.createElement("div", { style: { width: "100px", display: "inline-block" } },
            React.createElement(Select, { value: currentValue, onChange: handleCardChange, options: cardOptions, formatOptionLabel: function (_a) {
                    var value = _a.value;
                    return value !== undefined && value !== null && value !== "" ? (React.createElement(InlineCard, { card: value })) : (value);
                } })),
        React.createElement("div", { style: { width: "100px", display: "inline-block", marginLeft: "10px" } },
            React.createElement(Select, { value: props.friend.initial_skip !== null
                    ? {
                        value: "".concat(props.friend.initial_skip),
                        label: "#".concat(props.friend.initial_skip + 1),
                    }
                    : undefined, onChange: handleOrdinalChange, options: ArrayUtils.range(props.num_decks, function (idx) {
                    return { value: "".concat(idx), label: "#".concat(idx + 1) };
                }) }))));
};
export default FriendSelect;

import * as React from "react";
import InlineCard from "./InlineCard";
import classNames from "classnames";
import ArrayUtil from "./util/array";
var renderMessage = function (message) {
    var _a, _b, _c;
    var variant = (_a = message.data) === null || _a === void 0 ? void 0 : _a.variant;
    switch (variant === null || variant === void 0 ? void 0 : variant.type) {
        case "MadeBid":
            return (React.createElement("span", null, (_b = message.data) === null || _b === void 0 ? void 0 :
                _b.actor_name,
                " bid",
                " ",
                ArrayUtil.range(variant.count, function (i) { return (React.createElement(InlineCard, { card: variant.card, key: i })); })));
        case "PlayedCards":
            return (React.createElement("span", null, (_c = message.data) === null || _c === void 0 ? void 0 :
                _c.actor_name,
                " played",
                " ",
                variant.cards.map(function (card, i) { return (React.createElement(InlineCard, { card: card, key: i })); })));
        case "EndOfGameKittyReveal":
            return (React.createElement("span", null,
                variant.cards.map(function (card, i) { return (React.createElement(InlineCard, { card: card, key: i })); }),
                " ",
                "in kitty"));
        case "GameScoringParametersChanged":
            return renderScoringMessage(message);
        default:
            return React.createElement("span", null, message.message);
    }
};
var renderScoringMessage = function (message) {
    var _a, _b;
    var changes = [];
    var variant = (_a = message.data) === null || _a === void 0 ? void 0 : _a.variant;
    if ((variant === null || variant === void 0 ? void 0 : variant.type) === "GameScoringParametersChanged") {
        if (variant.old_parameters.step_size_per_deck !==
            variant.parameters.step_size_per_deck) {
            changes.push(React.createElement("span", { key: changes.length },
                "step size: ",
                variant.parameters.step_size_per_deck,
                "\u5206 per deck"));
        }
        if (variant.old_parameters.deadzone_size !== variant.parameters.deadzone_size) {
            changes.push(React.createElement("span", { key: changes.length },
                "non-leveling steps: ",
                variant.parameters.deadzone_size,
                " "));
        }
        if (variant.old_parameters.num_steps_to_non_landlord_turnover !==
            variant.parameters.num_steps_to_non_landlord_turnover) {
            changes.push(React.createElement("span", { key: changes.length },
                "steps to turnover:",
                " ",
                variant.parameters.num_steps_to_non_landlord_turnover,
                " "));
        }
        for (var k in variant.parameters.step_adjustments) {
            var adj = variant.parameters.step_adjustments[k];
            if (adj !== variant.old_parameters.step_adjustments[k]) {
                changes.push(React.createElement("span", { key: changes.length },
                    "step size adjustment for ",
                    k,
                    " decks set to ",
                    adj,
                    " "));
            }
        }
        for (var k in variant.old_parameters.step_adjustments) {
            var adj = variant.parameters.step_adjustments[k];
            if (adj === undefined || adj === null || adj === 0) {
                changes.push(React.createElement("span", { key: changes.length },
                    "adjustment for ",
                    k,
                    " decks removed "));
            }
        }
        if (variant.old_parameters.bonus_level_policy !==
            variant.parameters.bonus_level_policy) {
            if (variant.parameters.bonus_level_policy ===
                "BonusLevelForSmallerLandlordTeam") {
                changes.push(React.createElement("span", { key: changes.length }, "small-team bonus enabled"));
            }
            else {
                changes.push(React.createElement("span", { key: changes.length }, "small-team bonus disabled"));
            }
        }
        return (React.createElement("span", null, (_b = message.data) === null || _b === void 0 ? void 0 :
            _b.actor_name,
            " updated the scoring parameters: ",
            changes));
    }
    else {
        return React.createElement(React.Fragment, null);
    }
};
var ChatMessage = function (props) {
    var _a;
    var message = props.message;
    return (React.createElement(React.Fragment, null,
        ((_a = message.data) === null || _a === void 0 ? void 0 : _a.variant.type) === "StartingGame" ? (React.createElement("p", { className: classNames("message", {
                "game-message": message.from_game,
            }) }, "\uD83D\uDE9C \uD83D\uDE9C \uD83D\uDE9C \uD83D\uDE9C \uD83D\uDE9C \uD83D\uDE9C \uD83D\uDE9C \uD83D\uDE9C \uD83D\uDE9C \uD83D\uDE9C \uD83D\uDE9C \uD83D\uDE9C")) : null,
        React.createElement("p", { className: classNames("message", { "game-message": message.from_game }) },
            "from" in message && React.createElement("span", null,
                message.from,
                ": "),
            renderMessage(message))));
};
export default ChatMessage;

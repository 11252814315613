import preloadedCards from "../preloadedCards";
import ArrayUtils from "../util/array";
export var cardLookup = ArrayUtils.mapObject(preloadedCards, function (c) { return [c.value, c]; });
var suitToUnicode = {
    clubs: "♧",
    diamonds: "♢",
    hearts: "♡",
    spades: "♤",
};
var suitToFilledUnicode = {
    clubs: "♣",
    diamonds: "♦",
    hearts: "♥",
    spades: "♠",
};
var cardInfoToSuit = function (cardInfo) {
    switch (cardInfo.typ) {
        case "♢":
            return "diamonds";
        case "♧":
            return "clubs";
        case "♡":
            return "hearts";
        case "♤":
            return "spades";
        default:
            throw new Error("Invalid cardInfo");
    }
};
export var unicodeToCard = function (unicode) {
    if (unicode === "🂠") {
        return { type: "unknown" };
    }
    if (!(unicode in cardLookup)) {
        throw new Error("Invalid card string: ".concat(unicode));
    }
    var cardInfo = cardLookup[unicode];
    if (unicode === "🃟") {
        return { type: "little_joker" };
    }
    else if (unicode === "🃏") {
        return { type: "big_joker" };
    }
    else {
        return {
            rank: cardInfo.number,
            suit: cardInfoToSuit(cardInfo),
            type: "suit_card",
        };
    }
};
export var cardToUnicodeSuit = function (card, fill) {
    if (fill === void 0) { fill = true; }
    var table = fill ? suitToFilledUnicode : suitToUnicode;
    return table[card.suit];
};

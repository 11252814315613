var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var sum = function (array) { return array.reduce(function (a, b) { return a + b; }, 0); };
var identity = function (l, r) { return l === r; };
// Subtracts one array from another. Works with arrays with duplicate values,
// and throws an exception if the smaller array is not completely contained in
// the larger one.
var minus = function (large, small, equality) {
    if (equality === void 0) { equality = identity; }
    var result = __spreadArray([], large, true);
    small.forEach(function (valueToRemove) {
        var index = result.findIndex(function (t) { return equality(t, valueToRemove); });
        if (index >= 0) {
            result.splice(index, 1);
        }
    });
    return result;
};
var mapObject = function (array, mapper) {
    var result = {};
    array.forEach(function (t) {
        var _a = mapper(t), key = _a[0], value = _a[1];
        result[key] = value;
    });
    return result;
};
var range = function (count, fn) {
    return count !== undefined && count >= 0
        ? Array(count)
            .fill(undefined)
            .map(function (_, idx) { return fn(idx); })
        : [];
};
var shuffled = function (array) {
    return array
        .map(function (a) { return ({ sort: Math.random(), value: a }); })
        .sort(function (a, b) { return a.sort - b.sort; })
        .map(function (a) { return a.value; });
};
export default {
    mapObject: mapObject,
    minus: minus,
    range: range,
    sum: sum,
    shuffled: shuffled,
};

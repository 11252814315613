import * as React from "react";
var AutoPlayButton = function (props) {
    var onSubmit = props.onSubmit, canSubmit = props.canSubmit, isCurrentPlayerTurn = props.isCurrentPlayerTurn, playDescription = props.playDescription, currentWinner = props.currentWinner, unsetAutoPlayWhenWinnerChanges = props.unsetAutoPlayWhenWinnerChanges;
    var _a = React.useState(null), autoplay = _a[0], setAutoplay = _a[1];
    React.useEffect(function () {
        if (autoplay !== null) {
            if (!canSubmit) {
                setAutoplay(null);
            }
            else if (unsetAutoPlayWhenWinnerChanges &&
                autoplay.observedWinner !== currentWinner) {
                setAutoplay(null);
            }
            else if (isCurrentPlayerTurn) {
                setAutoplay(null);
                onSubmit();
            }
        }
    }, [
        autoplay,
        canSubmit,
        currentWinner,
        isCurrentPlayerTurn,
        unsetAutoPlayWhenWinnerChanges,
    ]);
    var handleClick = function () {
        if (isCurrentPlayerTurn) {
            onSubmit();
        }
        else if (autoplay !== null) {
            setAutoplay(null);
        }
        else {
            setAutoplay({ observedWinner: currentWinner });
        }
    };
    return (React.createElement("button", { className: "big", onClick: handleClick, disabled: !canSubmit }, isCurrentPlayerTurn
        ? "Play selected cards".concat(playDescription !== null ? " (" + playDescription + ")" : "")
        : autoplay !== null
            ? "Don't autoplay selected cards"
            : "Autoplay selected cards"));
};
export default AutoPlayButton;
